export interface IToysProps {
  _id: string;
  id: number;
  createdAt: number;
  updateAt: number;
  color: string | null;
  description: string;
  familyName: string | null;
  image: string;
  owner: string;
  model3d: string;
  size: string | null;
  status: string;
  type: string;
}

export interface IMintStagesOpenProps {
  startAt: number;
}

export interface IToysAvailability {
  availability: number;
}

export enum ToyStatus {
  OPEN = "open",
  VEILED = "veiled",
  SEALED = "sealed",
  PET = "pet",
}
