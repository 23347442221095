import { ReactElement } from "react";

import styles from "./Error.module.scss";

const Error = (): ReactElement => (
  <div className={styles.content}>
    <p data-testid="message-error">
      Transaction Failed. <br />
      Something went wrong. Please, try again.
    </p>
  </div>
);

export default Error;
