import { Link } from "react-router-dom";
import { MY_COLLECTION } from "../../constants";
import styles from "./BackButton.module.scss";

const BackButton = () => (
  <Link to={MY_COLLECTION}>
    <div className={styles.backButton}>BACK</div>
  </Link>
);

export default BackButton;
