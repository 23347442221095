import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./ProductPet.module.scss";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import Error404 from "../Error404";
import collection from "../../assets/images/my-collection/collection.svg";
import titleCollection from "../../assets/images/my-collection/my-collection.svg";
import useBackendApi from "../../hooks/useBackendApi";
import petsApi from "../../requests/petsApi";
import BackButton from "../BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPetsProps } from "../../types";
import Modal from "./Modal";
import ModalImage from "./ModalImage";
import Button from "../Buttons/Button";
import { IWeb3ContextProps, Web3Context } from "../../context/web3Context";
import { PET_CONTRACT_ADDRESS } from "../../constants";
import { ContractContextProvider } from "../../context/contractContext";
import Header from "../Header";

const ProductPet = (): ReactElement => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);
  const { walletAddress } = useContext(Web3Context) as IWeb3ContextProps;

  let { id } = useParams<{ id: string }>();

  const getPetApi = useBackendApi(petsApi.getPetById);

  useEffect(() => {
    getPetApi.request(id);
  }, []);

  const textTransformed = (text: string | null) => {
    if (!text) {
      return;
    }

    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  const petData = getPetApi.data as unknown as IPetsProps;

  const specialNameArray =
    petData?.properties.filter((el) => el.name === "X ID") ?? [];

  const toggleOpenModalImage = () => {
    setOpenModalImage(!openModalImage);
  };

  if (getPetApi.error) {
    return <Error404 />;
  }

  return (
    <>
      <div className={styles.myCollection}>
        <Header />
        <div className={styles.contentCollection}>
          {getPetApi.loading && <p>Toy is loading!</p>}
          {getPetApi.error && <p>{getPetApi.error}</p>}
          <div className={styles.toyInfoContainer}>
            <div className={styles.contentDescription}>
              <div className={styles.backButtonContainer}>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={styles.arrowBack}
                />
                <BackButton />
              </div>
              {petData?.owner !== walletAddress && (
                <img
                  className={styles.collection}
                  src={collection}
                  alt="collection"
                />
              )}
              {petData?.owner === walletAddress && (
                <img
                  className={styles.myCollectionTitle}
                  src={titleCollection}
                  alt="title collection"
                />
              )}
              <div className={styles.petImageContainerMobile}>
                <img
                  src={petData?.image}
                  alt="toy"
                  className={styles.petImageMobile}
                  onClick={toggleOpenModalImage}
                  role="button"
                />
              </div>
              <p className={styles.title}>
                {specialNameArray[0]?.value ?? textTransformed(petData?.type)}
              </p>
              <p className={styles.tokenId}>
                #{petData?.id}
                {" - "}
                {petData?.familyName}
              </p>
              <div className={styles.productDescriptionContainer}>
                <p className={styles.productDescription}>
                  Say hello to your OG PET. This PET is unique in its kind,
                  there will never be another PET like this one.
                  <br />
                  <br /> This PET is your friend and will allow you to enjoy
                  different games and experiences across the Rareverse. Be sure
                  to take good care of it. Have fun and explore together!
                </p>
              </div>
              <div className={styles.contentButton}>
                {petData?.owner === walletAddress && (
                  <a
                    href={`https://opensea.io/assets/ethereum/${PET_CONTRACT_ADDRESS}/${petData?.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button text="SELL ME" alt="button the sell" />
                  </a>
                )}
                {petData?.owner !== walletAddress && (
                  <div className={styles.buttonOpensea}>
                    <a
                      href={`https://opensea.io/assets/ethereum/${PET_CONTRACT_ADDRESS}/${petData?.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button text="VIEW ON OPENSEA" alt="View on Opensea" />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.petImageContainer}>
              <img
                className={styles.petImage}
                src={petData?.image}
                onClick={toggleOpenModalImage}
                role="button"
                alt="Pet"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal open={openModal} setOpenModal={setOpenModal} toyId={petData?.id} />
      <ModalImage
        open={openModalImage}
        setOpenModal={setOpenModalImage}
        image={petData?.image}
      />
    </>
  );
};

const ProductProvider = () => (
  <ContractContextProvider>
    <ProductPet />
  </ContractContextProvider>
);

export default ProductProvider;
