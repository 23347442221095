import {
  ReactElement,
  useEffect,
  useState,
  useContext,
  FormEvent,
} from "react";
import { useHistory } from "react-router-dom";

import { IWeb3ContextProps, Web3Context } from "../../context/web3Context";
import useContract from "../../hooks/contracts/useContract";
import useTotalToken from "../../hooks/contracts/useTotalToken";
import useBackendApi from "../../hooks/useBackendApi";
import toysApi from "../../requests/toysApi";
import signatureApi from "../../requests/signatureApi";
import mintStagesApi from "../../requests/mintStagesApi";

import { MY_COLLECTION } from "../../constants";
import { ISignatureProps, IMintStagesProps } from "../../types";
import alienBaby from "../../assets/images/mint/alien-baby.png";
import schoolKid from "../../assets/images/mint/school-kid.png";
import snowCone from "../../assets/images/mint/snow-cone.png";
import Mint from "./Mint";

import {
  useContractContext,
  ContractContextProvider,
} from "../../context/contractContext";

const MintContainer = (): ReactElement => {
  const { walletAddress, web3, connectWallet, chainId } = useContext(
    Web3Context
  ) as IWeb3ContextProps;
  const { stateContract, toysMintedTotal, toysTotalToken, setStateContract } =
    useContractContext();
  const getMintStagesApi = useBackendApi(mintStagesApi.getMintStages);

  const DEFAULT_MINT_QUANTITY = 1;
  const PRICE = 0.25;

  const [maxPerTransaction, setMaxPerTransaction] = useState(1);
  const [amount, setAmount] = useState(3);
  const [openModal, setOpenModal] = useState(false);
  const [total, setTotal] = useState(amount * PRICE);

  useEffect(() => {
    getMintStagesApi.request();
  }, []);

  const mintStages = getMintStagesApi.data as unknown as IMintStagesProps[];

  useEffect(() => {
    if (!mintStages) {
      return;
    }

    setMaxPerTransaction(mintStages[2]?.publicMintAmount);
  }, [mintStages]);

  const history = useHistory();

  const getToysApi = useBackendApi(toysApi.getToys);

  const getSignatureApi = useBackendApi(signatureApi.getSignature);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    getToysApi.request(walletAddress);
    getSignatureApi.request(walletAddress, amount);
  }, [walletAddress, openModal]);

  const signature = getSignatureApi.data as unknown as ISignatureProps;

  const { totalSupply, totalTokens } = useTotalToken(web3);

  const { mint } = useContract(web3, walletAddress, signature);

  useEffect(() => {
    totalSupply();
    totalTokens();
  }, [toysTotalToken, toysMintedTotal, chainId]);

  useEffect(() => {
    setTotal(amount * PRICE);
  }, [amount]);

  useEffect(() => {
    if (toysMintedTotal >= toysTotalToken) {
      return;
    }

    setTotal(amount * PRICE);
  }, [toysMintedTotal]);

  const toys = [
    {
      id: 1,
      name: "ALIEN BABY",
      image: alienBaby,
    },
    {
      id: 2,
      name: "SCHOOL KID",
      image: schoolKid,
    },
    {
      id: 3,
      name: "SNOW CONE",
      image: snowCone,
    },
  ];

  const handleOpenModal = () => {
    if (toysMintedTotal === toysTotalToken) {
      return;
    }

    setOpenModal(true);
  };

  const handleAdd = () => {
    if (amount === maxPerTransaction) {
      return;
    }

    const result = amount + 1;
    setAmount(result);
    setTotal(PRICE * result);
  };

  const handleSubstract = () => {
    if (amount <= DEFAULT_MINT_QUANTITY) {
      return;
    }

    const result = amount - 1;
    setAmount(result);
    setTotal(PRICE * result);
  };

  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    mint(amount);
  };

  const redirectMyCollection = () => {
    setOpenModal(false);
    history.push(MY_COLLECTION);
  };

  return (
    <Mint
      toys={toys}
      amount={amount}
      setAmount={setAmount}
      PRICE={PRICE}
      total={total}
      handleOnSubmit={handleOnSubmit}
      stateContract={stateContract}
      setStateContract={setStateContract}
      openModal={openModal}
      setOpenModal={setOpenModal}
      redirectMyCollection={redirectMyCollection}
      handleOpenModal={handleOpenModal}
      walletAddress={walletAddress}
      connectWallet={connectWallet}
      toysMintedTotal={toysMintedTotal}
      toysTotalToken={toysTotalToken}
      handleAdd={handleAdd}
      handleSubstract={handleSubstract}
      maxPerTransaction={maxPerTransaction}
      DEFAULT_MINT_QUANTITY={DEFAULT_MINT_QUANTITY}
      chainId={chainId}
    />
  );
};

const MintProvider = () => (
  <ContractContextProvider>
    <MintContainer />
  </ContractContextProvider>
);

export default MintProvider;
