import { ReactElement } from "react";
import styles from "./TheRareTeam.module.scss";

interface IProps {
  image: string;
  alt: string;
  name: string;
  description: string;
  link?: string;
}

const TeamMember = ({
  image,
  alt,
  name,
  description,
  link,
}: IProps): ReactElement => (
  <div className={styles.presentation}>
    <div className={styles.contentImage}>
      <a href={link} target="_blank" rel="noreferrer noopener">
        <img src={image} alt={alt} />
      </a>
    </div>
    <p className={styles.name}>{name}</p>
    <p className={styles.description}>{description}</p>
  </div>
);

export default TeamMember;
