import { ReactElement, FormEvent } from "react";

import styles from "./Minting.module.scss";

import backButton from "../../../../../assets/images/mint/back.svg";
import mintButton from "../../../../../assets/images/mint/mint.svg";

interface IProps {
  setOpenModal: (open: boolean) => void;
  handleOnSubmit: (e: FormEvent) => void;
  amount: number;
  total: Number;
}

const Minting = ({
  handleOnSubmit,
  setOpenModal,
  amount,
  total,
}: IProps): ReactElement => (
  <form onSubmit={handleOnSubmit}>
    <div className={styles.content}>
      <p>
        You selected to mint <span>{amount}</span> Toys for a total{" "}
        <span>{total}</span> ETH.
      </p>
      <p>Do you want to continue?</p>
      <div className={styles.contentButton}>
        <img
          src={backButton}
          alt="back"
          onClick={() => setOpenModal(false)}
          role="button"
        />
        <input type="image" src={mintButton} alt="mint" />
      </div>
    </div>
  </form>
);

export default Minting;
