export const HOME = "/";
export const ROADMAP = "/roadmap";
export const RAREPAPER = "/rarepaper";
export const MINT = "/mint";
export const MY_COLLECTION = "/my-collection";
export const MY_COLLECTION_PRODUCT = "/my-collection/:id";
export const MY_COLLECTION_PETS_PRODUCT = "/my-collection/pets/:id";
export const FAQ = "/faq";
export const RARERAFFLE = "/rareraffle";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
