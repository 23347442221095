import { ReactElement, useContext, useEffect, FormEvent } from "react";

import styles from "./OpenToy.module.scss";

import backButton from "../../../../assets/images/my-collection/back.svg";
import openToyButton from "../../../../assets/images/my-collection/open-toy.svg";
import {
  IWeb3ContextProps,
  Web3Context,
} from "../../../../context/web3Context";
import useBackendApi from "../../../../hooks/useBackendApi";
import signatureOpenToy from "../../../../requests/signatureOpenApi";
import { useContractContext } from "../../../../context/contractContext";
import useContractOpenToy from "../../../../hooks/contracts/useContractOpenToy";
import { IOpenSignatureProps } from "../../../../types";
import { ETransactionStatus } from "../../../../constants/enums";

interface IProps {
  setStep: (value: number) => void;
  step: number;
  familyName: string;
  validation: string;
  toyId: number;
}

const OpenToy = ({
  setStep,
  step,
  familyName,
  validation,
  toyId,
}: IProps): ReactElement => {
  const { walletAddress, web3 } = useContext(Web3Context) as IWeb3ContextProps;

  const { stateOpenToyContract, setStateOpenToyContract } =
    useContractContext();

  const signatureOpen = useBackendApi(signatureOpenToy.getSignatureOpen);

  useEffect(() => {
    signatureOpen.request(walletAddress, toyId, familyName);
  }, []);

  const signature = signatureOpen.data as unknown as IOpenSignatureProps;

  const { open } = useContractOpenToy(web3, walletAddress, signature);

  const status = stateOpenToyContract.payload.status;

  const handleOnSubmit = (e: FormEvent) => {
    if (validation !== "") {
      setStep(step - 1);
      return;
    }

    e.preventDefault();
    open();
  };

  useEffect(() => {
    setStateOpenToyContract({
      type: "CALLING_CONTRACT",
      payload: {
        status: "WAITING_CONFIRMATION",
      },
    });
  }, []);

  useEffect(() => {
    if (
      status === ETransactionStatus.ERROR ||
      status === ETransactionStatus.IN_PROGRESS
    ) {
      setStep(step + 1);
    }
  }, [stateOpenToyContract.type]);

  return (
    <form onSubmit={handleOnSubmit}>
      <div className={styles.content}>
        <p className={styles.text}>Your Family Name will be:</p>
        <p className={styles.name}>{familyName}</p>
        <p className={styles.text}> Are you ready to open your TOY?</p>
        <div className={styles.contentButton}>
          <img
            src={backButton}
            role="button"
            alt="Back"
            onClick={() => setStep(step - 1)}
          />
          <input type="image" src={openToyButton} alt="Open" />
        </div>
      </div>
    </form>
  );
};

export default OpenToy;
