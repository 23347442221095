import { ReactElement } from "react";

import styles from "./TermsAndConditions.module.scss";
import titleTermsAndConditions from "../../assets/images/termsAndConditions/terms-and-conds.svg";

import Header from "../Header";

const TermsAndConditions = (): ReactElement => (
  <div className={styles.termsAndConditions}>
    <Header />
    <img
      src={titleTermsAndConditions}
      alt="Terms and Conditions"
      className={styles.titleTermsAndConditions}
    />
    <div className={styles.contentText}>
      <p>
        <strong>
          Before using this website, we suggest that you read our T&C. By using
          this website, the user accepts the conditions presented.
        </strong>
      </p>
      <p>
        <strong>
          If the User does not agree with these T&C for contracting the services
          offered by “Raregotchi”, the User must abandon this website of
          “Raregotchi” and you will not contract the services offered on this
          page, respectively.
        </strong>
      </p>

      <p className={styles.titleText}>
        <strong>Terms & Conditions Raregotchi</strong>
      </p>

      <p>
        Raregotchi is a series of non-fungible and artistic, cryptographic
        tokens (“NFT”) (the “Project”) launched by Raregotchi, Unipessoal LDA,
        (the “Artists”), in collaboration with Emenio SA (the “Collector,”),
        both of them collectively, “the Project Team”.
      </p>
      <p>
        Raregotchi features Collectable Digital Assets created by the Artists.
        Each Collectable Digital Asset will initially correspond to a single NFT
        (a “NFT TOY”). The Project will consist on a series of future stages
        detailed in its white paper called “Rarepaper” (
        <span className={styles.contentLink}>
          <a href="https://rarepaper.raregotchi.com/project/raregotchi">
            <p className={styles.link}>
              https://rarepaper.raregotchi.com/project/raregotchi
            </p>
          </a>
        </span>
        ).
      </p>

      <p>
        <strong>1. Applicability of the Terms</strong>
      </p>

      <p>
        These Terms are displayed to regulate the use of the Platform that has
        been created specifically for the Project. These Terms form a binding
        agreement between each Collector or other individual or single entity
        accessing or making use of the Platform (collectively “Users,” each a
        “User”), on the one hand, and the Project Team, on the other.
        <ul>
          <li className={styles.column}>
            <p>1.1</p>
            <p>
              Moreover, these Terms are intended to manage use of and access to
              the website currently hosted https://raregotchi.com/ (the “Site”),
              including any pages and domains/subdomains within the Site,
              applications for smartphones, tablets and other devices, and all
              interactive features, functions, and application interfaces
              display on or offered by any of the foregoing (collectively, the
              “Platform”) and all associated services offered through the
              Platform (together referred to as the “Services”).
            </p>
          </li>
          <li className={styles.column}>
            <p>1.2</p>
            <p>
              The Project Team reserves the right, at its sole discretion, to
              change, modify, add or remove portions of these Terms at any time
              for any reason, and may notify Users of such changes through any
              of a variety of means, including a change to the 'Last Revised'
              date set forth above and other reasonable means to be determined
              at the Project Team's sole discretion. All changes shall be
              effective immediately. Please check these Terms periodically for
              changes. A User's continued use of the Site after the posting of
              changes constitutes the User's binding acceptance of such changes.
              If a User does not accept these Terms or any revisions thereto,
              such User’s use of and access to the Site shall be considered
              automatically terminated pursuant to point 13
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>2. Scope and Description of the Services</strong>{" "}
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>2.1</p>
            <p>
              The Platform includes a decentralized application linked to a
              digital art marketplace that operates on the Ethereum blockchain
              (the "Blockchain") - where it is possible to purchase certified
              Collectible NFTs, each corresponding to a NFT TOY created by The
              Artist as part of the Project. The Collectible NFTs will be sold
              through the Platform over the course of multiple drops (each, a
              “Drop”). The Collectible NFTs offered for sale are owned by or
              otherwise offered at the Project Team’s discretion.
            </p>
          </li>
          <li className={styles.column}>
            <p>2.2</p>
            <p>
              Each Collectible NFTs displayed on the Platform is created and
              converted by the Artist into an image file corresponding to (and
              referenced in the URI contained within) a single NFT. NFTs are
              tracked and stored without time limitation on the Ethereum’s
              Blockchain, providing the User with a permanent record of
              authenticity and ownership.
            </p>
          </li>
          <li className={styles.column}>
            <p>2.3</p>
            <p>
              The scope of the Services is limited to the issuance of NFTs for
              sale on the Platform. The Platform only manages sales in the
              primary market; for the avoidance of doubt, the Platform is not
              involved with or responsible for any sales made in the secondary
              market.
            </p>
          </li>
          <li className={styles.column}>
            <p>2.4</p>
            <p>
              The Platform relies on third party service providers. The Site may
              contain links to third-party websites ("Third-Party Service
              Providers") or may make use of additional services provided by
              Third-Party Service Providers, including without limitation
              digital wallet providers. The use of such products, services,
              applications or websites will be governed by and subject to the
              terms and conditions and privacy policies of the relevant
              Third-Party Service Provider.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>3. Privacy</strong>
      </p>
      <p>
        The protection of personal information is extremely important to the
        Project Team. Each User acknowledges having reviewed the Privacy Policy
        available at:
        <ul>
          <li className={styles.column}>
            <p>3.1</p>
            <p>
              and hereby expressly agrees to the terms thereof with respect to
              use and processing of Users’ personal information.
            </p>
          </li>
          <li className={styles.column}>
            <p>3.2</p>
            <p>
              The User also acknowledges that the account is personal and agree
              not to provide any other person with access to the Services or
              portions of them using his user’s name, password, or other
              security information — not even to another person associated with
              business, but not part of it. The User agrees to notify us
              immediately of any unauthorized access to or use of his user’s
              name or password or any other breach of security. If a User is
              using a public or shared computer, also agree to ensure that they
              exit from the Platform at the end of each session. Each User
              should observe caution when accessing its account from a public or
              shared computer so that others are not able to view or record
              user’s password or other personal information. We have the right
              to disable any username, password, or other identifier, whether
              chosen by a User or provided by us, at any time if, in our
              opinion, they have violated any provision of these Terms of
              Service.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>
          4. User License to the Services and Intellectual Property Rights
        </strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>4.1</p>
            <p>
              The Services and their entire contents, features, and
              functionality (including but not limited to all information,
              software, text, displays, images, video, and audio, and the
              design, selection, and arrangement thereof) are owned by Artists,
              its licensors, or other providers of such material and are
              protected by Portugal, European and international copyright,
              trademark, patent, trade secret, and other intellectual property
              or proprietary rights laws. Under these Terms, the Artist grants
              each User a revocable, limited, free, non-exclusive,
              non-transferable and non-sublicensable license (the “Services
              License”) to access and use the Services through the computer or
              other internet-compatible devices for personal use.
            </p>
          </li>
          <li className={styles.column}>
            <p>4.2</p>
            <p>
              Users may not use the Services for resale or for any commercial
              purpose not permitted under the terms of the Services License.
              Resale of the Services is expressly prohibited and constitutes a
              serious violation of these Terms.
            </p>
          </li>
          <li className={styles.column}>
            <p>4.3</p>
            <p>
              These Terms are intended to grant only a limited license to access
              and use the Platform and/or Services. Each User hereby expressly
              acknowledges and agrees that User’s use of and/or access to the
              Platform and/or Services does not result in any ownership or
              intellectual property rights therein being transferred to User.
            </p>
          </li>
          <li className={styles.column}>
            <p>4.4</p>
            <p>
              Text, graphics, user interfaces, visual interface, photos, sounds,
              process flow diagrams, computer code (including html code),
              programs, software, products, information and the documents, as
              well as the design, structure, selection, expression, appearance
              and layout of any content included in the Services or provided
              through the Services are the exclusive property of Artists.
            </p>
          </li>
          <li className={styles.column}>
            <p>4.5</p>
            <p>
              If a User prints, copies, modifies, downloads, or otherwise use or
              provide any other person with access to any part of the Services
              in breach of the Terms of Service, its right to use the Services
              will stop immediately and the User must, return or destroy any
              copies of the materials it has made. No right, title, or interest
              in or to the Services or any content on the Services is
              transferred to the user, and all rights not expressly granted are
              reserved by the Project Team. Any use of the Services not
              expressly permitted by these Terms of Service is a breach of these
              Terms of Service and may violate copyright, trademark, and other
              laws. The Raregotchi name, marks including the Raregotchi logos,
              and all related names, logos, product and service names, designs,
              and slogans are trademarks of Raregotchi LDA. The User must not
              use such marks without the prior written permission of the
              Artists. All other names, logos, product and service names,
              designs, and slogans that may appear in the Services are the
              trademarks of their respective owners.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>5. Eligibility to Use the Site and/or Services:</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>5.1</p>
            <p>
              To use the Services offered by the Platform, the User represents
              and warrants that User has reached the age of majority and has
              full legal capacity. While individuals under the age of 18 (but
              not younger than 13) may use the Site and/or Services, they must
              do so only with the consent and involvement of a parent or legal
              guardian, and otherwise subject to these Terms.
            </p>
          </li>
          <li className={styles.column}>
            <p>5.2</p>
            <p>
              To participate in the minting or purchase of, or to custody a
              purchased NFT minted or purchased through the Platform, the User
              must necessarily download a third-party browser extension for the
              creation of a supported digital wallet and connect it to the
              Platform. The User will not be able to carry out any transactions
              on the Platform except through MetaMask or other in-wallet
              browsers compatible with Ethereum.
            </p>
          </li>
          <li className={styles.column}>
            <p>5.3</p>
            <p>
              The Project Team reserves the right to identify participants in
              each Drop and award such participants one or more “mints” in a
              Drop (such participants thereby becoming “Listed Participants”) by
              means of raffles or other eligibility criteria established at the
              Project Team’s Artist’s sole discretion, including without
              limitation as set forth in the Artists’ Discord channels.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>6. Prohibited Uses</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>6.1</p>
            <p>
              Users may use the Services only for lawful purposes and in
              accordance with these Terms of Service. Users agree not to use the
              Services:
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>A) </p>
            <p className={styles.onlyText}>
              In any way that competes with Project Team business.
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>B) </p>
            <p className={styles.onlyText}>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from
              Portugal or other countries).
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>C) </p>
            <p className={styles.onlyText}>
              To send, knowingly receive, upload, download, use, or re-use any
              material that does not comply with the Content Standards set out
              in these Terms of Service.
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>D) </p>
            <p className={styles.onlyText}>
              To transmit, or procure the sending of, any mass advertising or
              promotional material, including any “junk mail,” “chain letter,”
              “spam,” or any other similar solicitation.
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>E) </p>
            <p className={styles.onlyText}>
              To impersonate or attempt to impersonate a Project Team employee,
              another user, or any other person or entity (including, without
              limitation, by using email addresses or user names associated with
              any of the foregoing).
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>F) </p>
            <p className={styles.onlyText}>
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Services, or which, as determined by us,
              may harm Raregotchi or users of the Services, or expose them to
              liability.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>7. General Conditions of Sale</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>7.1</p>
            <p>
              Prices and Transaction Fees: Transactions on the Platform are not
              subject to commissions. Buyers are required to pay only the
              minting price identified at the time of a Drop, in addition to any
              additional costs and gas fees (which, for the avoidance of doubt,
              remain the responsibility of the User conducting the transaction).
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>8. Objects of the Sale</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>8.1</p>
            <p className={styles.onlyText}>
              <p>
                Raregotchi will initially issue a total of 3000 NFT TOYS. An NFT
                TOY is a Non Fungible Token of a Digital Toy of Raregotchi.
                There will be 3 types of NFT TOYS (School Kid, Alien baby and
                Snow Cone) 1000 of each type; and 3 digital sizes ( Regular,
                Large and Huge) . Each type of NFT TOYs will have the same
                quantity of each digital size, but the quantity of NFT TOYs of
                each size, will be random.
              </p>
              <p>
                The NFT TOYs will be sold randomly, both in terms of type of NFT
                TOY and of size, to the buyers through our website in 3
                different stages starting on June 30th 2022 . The quantity of
                NFT TOYs that each user will be allowed to purchase (mint) will
                vary according to a series of factors, such as wallet address
                (contributors), stage of mint, amont others.
              </p>
              <p>
                The last stage of minting, the public one, will be open and thus
                will have no restrictions on the amount of NFT TOYs that could
                be minted by each wallet address, but there will be a minting
                cap of 3 NFT TOYs per transaction.
              </p>
              <p>
                Initially, NFT TOYs will be sold as “veiled” NFTs (non-revealed)
                and will stay like this until the “Unveiling event”. The date of
                such event will be announced shortly after the last stage of
                minting. , At the “Unveiling event” all the “veiled” NFT TOYs
                will automatically reveal their type and size, and thus become
                “unveiled”.
              </p>
              <p>
                The “unveiled” NFT TOY will then become a “Sealed NFT TOY”. That
                means that the resulting NFT will be composed by a sealed
                Package with a Toy inside. Shortly after the unveiling event,
                the “Opening” will take place, at which the Artists will allow
                the owners of the “Sealed NFT TOYs” to “open” them through an
                experience at Raregotchi’s website.
              </p>
              <p>
                Holders will be able to “Open” their NFT TOYs, changing the
                state of their original “ Sealed NFT TOY” to “Opened NFT TOY”
                related to the “Sealed” one. They also will get a
                pre-established amount of unique NFT PETs depending on the size.
                Three(3) NFT PETs will be granted by the Regular NFT TOY size,
                Four (4) by the Large and six (6) by the Huge.
              </p>
              <p>
                There will be a total of 9999 unique NFT PETs. These will be
                Non-Fungible Tokens of digital creatures of Raregotchi.
              </p>
              <p>
                The type of NFT PETs will be correlated to the type of NFT TOY
                which originated them (School Kid, Alien Baby and Snow Cone),
                but will be randomly assigned to each NFT TOYs, with different
                degrees of rarity, which will be, from least rare to rarest:
                Rare - Ultra Rare - Epic - Shiny - Legendary.
              </p>
              <p>
                On a near future holders will be able to claim through our
                website their “Package NFT”.
              </p>
              <p>
                The Package NFT will be a Non-Fungible Token of the digital
                opened package of the “NFT TOY”, and a collectible element of
                the project with future perks yet to be revealed.
              </p>
              <p>
                Thus, by “opening” a “Sealed NFT TOY”, that NFT will cease to
                exist and will be changed for: (a) an “Opened NFT Toy” (related
                to the Sealed one it came from), and (b) a determined number of
                randomly generated unique NFT PETs associated to each type of
                NFT TOY.
              </p>
              <p>
                A “Package NFT” (related to the Sealed one it came from) will be
                claimable in the near future.
              </p>
              <p>
                The owners of “Sealed NFT TOYs” might choose not to open them at
                the “Opening” event and keep them in that original condition. If
                they choose to “open” them in the future, they will be able to
                do so via our website.
              </p>
            </p>
          </li>
          <li className={styles.column}>
            <p>8.2</p>
            <p>
              Using those acquired NFTs, the Collector may use them in the
              platform and their associated services. Subsequent exchanges of
              Raregotchi NFTs may take place on OpenSea and/or through other
              Marketplaces.
            </p>
          </li>
          <li className={styles.column}>
            <p>8.3</p>
            <p>
              The processes described above may vary based on any changes to the
              Project at the sole discretion of the Project Team. The Artist
              expressly reserves the right to further develop the Project in
              their sole creative discretion, including without limitation by
              expanding and/or modifying the NFTs offered or otherwise made
              available.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>9. Collector License in Collectible NFT</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>9.1</p>
            <p className={styles.onlyText}>
              <p>
                Artists grant to each User a non-exclusive license (the
                “Non-Commercial Use License”) to the Collectible NFT(s) then
                owned by such User, solely for the following purposes (the
                “Purposes”):
              </p>
              <p>
                (a) displaying or causing the NFT to be displayed on any
                Collector-controlled social media account (including without
                limitation as Collector’s PFP);
              </p>
              <p>
                (b) promoting, discussing, or commenting on the NFT(s), User’s
                purchase and/or ownership thereof, and/or the Project, including
                without limitation for news, critical, archival, parodical
                and/or satirical purposes (e.g., visual “memes”);
              </p>
              <p>
                (c) displaying the NFT(s) on developer's platforms, third-party
                marketplaces, exchanges, sale platforms, or applications
                (collectively, “Marketplaces”) in association with an offer to
                sell, or trade, the corresponding Collectible NFT(s), provided
                that in each case the Marketplace cryptographically verifies
                User as the actual owner of the NFT(s) being so offered for
                sale. The Non-Commercial Use License is sub-licensable by the
                Collector solely if and as expressly required to fulfill the
                Purposes, and automatically transfers to the new owner of the
                NFT upon the Collector’s sale of the digital asset.
              </p>
            </p>
          </li>
          <li className={styles.column}>
            <p>9.2</p>
            <p>
              Notwithstanding the foregoing, the Artists reserve the right to
              revoke the Non-Commercial Use License with respect to any use of
              an NFT that is deemed by the Artist in its sole discretion to be
              abusive, offensive, illegal, or otherwise in violation of any part
              of this terms and conditions.
            </p>
          </li>
          <li className={styles.column}>
            <p>9.3</p>
            <p>
              Where practicable, displays of the Artwork or any portion thereof
              made pursuant to the License shall “tag” (i.e. embed in a social
              media post Raregotchi’s handle or username on such social media
              platform) or otherwise credit or reference Artists, in order to
              promote further connections between the Artist and Collectors.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>10. Additional Collector License to the PET NFTs</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>10.1</p>
            <p>
              Artists grant each Collector who owns a Pet NFT the worldwide,
              rights to reproduce, display, distribute, and make commercial use
              of the Pet NFT (including without limitation for commercial
              merchandise and derivative works) (such license, the “Commercial
              Use License,” and such contemplated uses, the “Pet NFT Uses”). The
              Commercial Use License is sub-licensable by the Collector solely
              if and as expressly required to undertake the Pet NFT Uses, and
              automatically transfers to the new owner of the Pet NFT upon the
              Collector’s sale of the Pet NFT.
            </p>
          </li>
          <li className={styles.column}>
            <p>10.2</p>
            <p>
              Notwithstanding the foregoing, the Commercial Use License does not
              extend to or permit Pet NFT Uses that are abusive, offensive,
              illegal, or otherwise in violation of point 13 of these Terms.
            </p>
          </li>
          <li className={styles.column}>
            <p>10.3</p>
            <p>
              The Artists welcome proposals from Users for NFT’s Uses that will
              proliferate the Project, the Raregotchi ethos, and the connections
              between members of the community. Such proposals must be made by
              e-mail to info@raregotchi.com
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>11. Additional Collector License to the PET NFTs</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>11.1</p>
            <p>
              Subject to availability, Users can purchase NFTs through the Site
              as established at the time of each Drop stage, i.e. through
              connection of a digital wallet to the Site to “mint” an NFT
              directly through the Site. The Collector will create the legal
              invoices for the entire Drop, complying with all applicable local
              laws.
            </p>
          </li>
          <li className={styles.column}>
            <p>11.2</p>
            <p>
              The Project Team may, alternatively or in subsequent phases of the
              Project, provide for the purchase of NFTs using an online auction
              mechanism, as hosted by the Platform and/or one or more
              Third-Party Service Providers.
            </p>
          </li>
          <li className={styles.column}>
            <p>11.3</p>
            <p>
              Each User acknowledges that the respective rights of the Artists
              (including resale royalty rights, as applicable) and the rights
              and obligations of the User transfer with the NFT and the time of
              any transfer of ownership of such NFT. Such rights may be
              processed or initiated directly through one or more smart
              contracts on the Ethereum blockchain network.
            </p>
          </li>
          <li className={styles.column}>
            <p>11.4</p>
            <p>
              Users offering Project-originating NFTs for secondary sale must do
              so through an on-chain Marketplace, and are obligated to condition
              any secondary purchaser’s acquisition of such NFT(s) on acceptance
              of and adherence to these Terms.
            </p>
          </li>
          <li className={styles.column}>
            <p>11.5</p>
            <p>
              The Project Team has no control over such transactions or the
              Ethereum network, cannot reverse or issue refunds in connection
              with such transactions, and is not liable for any loss, damage or
              harm that you may incur as a result of such transactions.
            </p>
          </li>
          <li className={styles.column}>
            <p>11.6</p>
            <p>
              The purchase of a Collectible NFT, like all transactions on the
              Ethereum network, will require payment of transaction charge (“Gas
              Fee”) to Ethereum, in addition to the stated purchase price of the
              Collectible NFT.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>
          12. Representations; Additional Disclaimers; User Release
        </strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>12.1</p>
            <p>
              The Project Team expressly disclaims any representation or
              warranty as to:
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>A) </p>
            <p className={styles.onlyText}>
              the connection or interaction between any NFT and the related TOY,
              PET or PACKAGE (together, the "Assets"), including without
              limitation the stability or duration of any link in the NFT
              referencing such corresponding assets or the continued display of
              the assets at such location;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>B) </p>
            <p className={styles.onlyText}>
              the relationship to, interaction between, compatibility with or
              functionality of any NFT or related Asset, on the one hand, and
              any Marketplace, computer system or other environment, game,
              contest, series, or similar, on the other;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>C) </p>
            <p className={styles.onlyText}>
              the status or state of any NFT, underlying smart contract, or any
              related Asset or any transfer mechanism for any NFT as free of
              viruses or other harmful components, or the functionality of the
              foregoing as a buyer expects or without fault;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>D) </p>
            <p className={styles.onlyText}>
              that an NFT or Smart Contract is reliable, compatible with any
              particular computer system(s) or platforms, error-free, compatible
              with a User's digital wallet or otherwise meets the User's
              requirements, or that any defects in any NFT or its smart contract
              can or will be corrected;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>E) </p>
            <p className={styles.onlyText}>
              the accuracy or reliability of any depiction of the intended
              performance of any NFT, any smart contract, or any related Asset;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>F) </p>
            <p className={styles.onlyText}>
              Without limiting the foregoing, each User expressly accepts all
              risks associated with purchasing, using, transferring, and owning
              NFTs generally, including without limitation, temporary
              inaccessibility due to network, server, or blockchain protocol
              change, failures, malfunctions, or disruptions; risk of losing
              access due to lost or forgotten private key(s) or password(s) or
              corrupted wallet files; mis-typed addresses or incorrectly
              constructed transactions; viruses, phishing, hacking, security
              breaches or attacks; and regulatory implications (including
              without limitation as pertain to securities, cryptocurrencies,
              and/or capital gains or other taxation issues).
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>G) </p>
            <p className={styles.onlyText}>
              In addition, User acknowledges that the Platform is not affiliated
              with, and the Project Team does not own or control, MetaMask,
              Coinbase, Google Chrome, the Ethereum network or any other third
              party site, product or service that the User may access, visit or
              use for the purpose of enabling such User to use the Platform
              and/or Services. The Project Team is not liable for the acts or
              omissions of such third-parties, and exempt from liability for any
              damage suffered by the User for any interaction with such
              third-parties.
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>H) </p>
            <p className={styles.onlyText}>
              In using the Site and/or the Services, each User expressly agrees
              to release, indemnify, defend, and hold harmless the Project Team
              and each of their officers, directors, employees, agents,
              affiliates, successors, and assigns from and against any and all
              claims, losses, costs, damages and expenses of whatever kind,
              including reasonable attorneys’ fees, arising from or relating to
              such User’s use or misuse of the Site and/or Services, including
              without limitation such User’s violation of these Terms or of any
              rights of any third-party, such User’s violation of applicable
              laws, including sanctions regulations, and/or any claims by or
              against such User of infringement of intellectual property or
              other proprietary rights.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>13. Termination, Suspension and Other Measures</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>13.1</p>
            <p>
              Termination by the User: The User can discontinue use of the
              Platform and/or Services at any time, without forfeiting or being
              precluded from accessing the NFTs purchased by such User up to
              that time, provided, however, that any such NFTs remain subject to
              the intellectual property rights set forth in these Terms
              (including without limitation the Non-Commercial Use License, if
              and as applicable).
            </p>
          </li>
          <li className={styles.column}>
            <p>13.2</p>
            <p>
              Termination or Suspension of Services by the Project Team: The
              Project Team reserves the right, at its sole discretion, for any
              or no reason, at any time, and without notice, to terminate a
              User's right to access or use the Platform, including without
              limitation in the event of the User’s violation of Terms’ point
              13. The Project Team has the right to suspend the Services if it
              has sufficient reasons to believe that a User is not acting in
              accordance with these Terms. For the avoidance of doubt, this
              means that the Project Team reserves the right to suspend a User’s
              access to the site, including without limitation by restricting
              connection of the User’s digital wallet with the Site.
            </p>
          </li>
          <li className={styles.column}>
            <p>13.3</p>
            <p>
              No Liability: User acknowledges and accepts that the Project Team
              will have no liability or obligation towards the User in the case
              of termination or suspension pursuant to this paragraph 13, and
              will not be entitled to a refund of any amount that User has
              already paid in connection with the Site or Services, including
              for any NFT.
            </p>
          </li>

          <li className={styles.column}>
            <p>13.4</p>
            <p>
              Additional Consequences and Remedies: The Project Team reserves
              the right to take additional measures not enumerated in these
              Terms to protect the Online Site and other Users from illegal,
              fraudulent or otherwise inappropriate behavior, including without
              limitation the following:
            </p>
          </li>

          <li className={styles.column}>
            <p className={styles.onlyText}>A) </p>
            <p className={styles.onlyText}>
              Reproduction, distribution, copying, sale or resale, trade, or
              exploitation any portion of the Site or content obtained through
              the Site, for any purpose that is inconsistent with these Terms;
            </p>
          </li>

          <li className={styles.column}>
            <p className={styles.onlyText}>B) </p>
            <p className={styles.onlyText}>
              Creation of derivative works of and/or modification, adaptation,
              or translation of the Site or any part or contents thereof except
              and only to the extent that such activity is expressly permitted
              by applicable law notwithstanding this limitation or under these
              Terms, or with the prior written authorization of the Project Team
              and any applicable licensors;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>C) </p>
            <p className={styles.onlyText}>
              Defamation, harassment, abuse, threats, stalking or defrauding
              Users of the Site, or collecting, or attempting to collect,
              personal information about Users or third-parties without their
              consent (or using the Site to engage in such conduct);
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>D) </p>
            <p className={styles.onlyText}>
              Posting, transmitting, reproducing, or disseminating any content
              through the Site (or elsewhere, including any other use) that the
              Project Team determines at its sole discretion: (i) is unlawful,
              harmful, harassing, fraudulent, threatening, abusive, libelous,
              defamatory, vulgar, obscene, hateful, or racially, ethnically or
              otherwise objectionable, including without limitation as a result
              of association with or use for any political figure, candidate or
              candidacy, campaign, or movement or any religious institution or
              similar, or infringes any third-party’s intellectual property,
              moral, or other rights, (ii) is derogatory or harmful to the
              Artists’ reputation (or otherwise in violation of the Artists’
              moral rights), the reputation of the Site's licensors, or any of
              the Project Team's or their respective officers, members,
              employees, representatives, licensors and/or suppliers, in any
              way; (iii) may incite violence or other unlawful activity; or (iv)
              is harmful to children in any manner;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>E) </p>
            <p className={styles.onlyText}>
              Intentionally interfering with or damaging, impairing or
              disabling, interrupting, impairing, or overburdening the operation
              of the Site or any User’s enjoyment of it by any means, including
              but not limited to uploading or otherwise disseminating viruses,
              worms, spyware, adware, or other malicious code, or placing a
              disproportionate load on the Site with the intended result of
              denying service to other Users, sending mass unsolicited messages
              or “flooding” servers;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>F) </p>
            <p className={styles.onlyText}>
              Removing, circumventing, disabling, damaging, or otherwise
              interfering with any security-related features of the Site,
              features that prevent or restrict the use or copying of any part
              of the Site, or features that enforce limitations on the use of
              the Site;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>G) </p>
            <p className={styles.onlyText}>
              Attempting to gain unauthorized access to the Site or any part of
              it, including gaining access or attempting to gain access to
              another User’s account, computer systems or networks connected to
              the Site or any part of it, through request, hacking, password
              mining, use of any robot, spider, scraper, or any other means;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>H) </p>
            <p className={styles.onlyText}>
              Reverse engineering, decompiling, disassembling or otherwise
              attempting to discover the source code of the Site or any part
              thereof, except and only to the extent that such activity is
              expressly permitted by applicable law notwithstanding this
              limitation;
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>I) </p>
            <p className={styles.onlyText}>
              Making unsolicited offers, advertisements, proposals, or sending
              junk mail or spam to other Users of the Site or inserting User’s
              own or a third-party’s advertising, branding or other promotional
              content on the Site; or
            </p>
          </li>
          <li className={styles.column}>
            <p className={styles.onlyText}>J) </p>
            <p className={styles.onlyText}>
              Impersonating any other person or entity, or falsely stating or
              otherwise misrepresenting your affiliation with a person or
              entity.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>14. Applicable Law and Dispute Resolution</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>14.1</p>
            <p>
              These Terms, access to and use of the Site, and Users’
              participation in any purchase through the Site of any NFT offered
              thereon, will be governed, construed, and enforced in accordance
              with the laws of the State of Portugal.
            </p>
          </li>

          <li className={styles.column}>
            <p>14.2</p>
            <p>
              Any dispute, claim, action, or case relating to, these Terms or
              otherwise in connection with the Platform (or any NFT offered
              thereon) and/or the Services, will be resolved by administered
              Portuguese Law and Portuguese Courts.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>15. Entire Agreement; No Waiver; Severability</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>15.1</p>
            <p>
              These Terms contain the entire understanding as to the
              relationship between the parties with reference to the subject
              matter hereof, it being understood that it replaces any previous
              understanding(s) and/or agreement(s) between the parties. The
              Project Team reserves the right to transfer and/or assign the
              rights and obligations deriving from these Terms to third parties.
            </p>
          </li>

          <li className={styles.column}>
            <p>15.2</p>
            <p>
              Any tolerance by the Project Team of a User's behavior in
              violation of the provisions contained in these Terms does not
              constitute a waiver of the rights deriving from the violated
              provisions nor the right to demand the exact fulfillment of all
              these Terms.
            </p>
          </li>
          <li className={styles.column}>
            <p>15.3</p>
            <p>
              In the event that any provision of the Terms ais or becomes
              unenforceable under any applicable law, or is determined to be
              null or inapplicable by any court or arbitrator of competent
              jurisdiction, and/or if one or more provisions cannot, for reasons
              other than the non-fulfillment of the obliged subject, be
              fulfilled in full compliance with the provisions agreed here, the
              remaining provisions will remain in full force and effect.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <strong>16. Contact and Assistance</strong>
      </p>
      <p>
        <ul>
          <li className={styles.column}>
            <p>16.1</p>
            <p>
              Users may seek support in relation to the Platform and/or Services
              through customer service by sending an e-mail to the address:
              info@raregotchi.com
            </p>
          </li>

          <li className={styles.column}>
            <p>16.2</p>
            <p>
              Without prejudice to the above, any question or comment regarding
              the Site and/or these Terms and, in general, any communication to
              the Project Team must be sent to the e-mail address:
              info@raregotchi.com
            </p>
          </li>
          <li className={styles.column}>
            <p>16.3</p>
            <p>
              The Project Team expressly reserves the right to make any further
              communication or send notifications and messages to Users through
              the Site.
            </p>
          </li>
        </ul>
      </p>

      <p>
        ALL RIGHTS RESERVED. EXCEPT AS OTHERWISE EXPRESSLY PERMITTED BY THESE
        TERMS, ANY ARTWORK DISPLAYED ON THE SITE MAY NOT BE REPRODUCED OR USED
        IN ANY MANNER WHATSOEVER WITHOUT THE ADVANCE WRITTEN PERMISSION OF
        RAREGOTCHI OR THEIR RESPECTIVE OWNERS.
      </p>
    </div>
  </div>
);

export default TermsAndConditions;
