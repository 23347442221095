import { ReactElement } from "react";

import backgroundButtonWeb from "../../../assets/images/buttonEmpty/button-empty-web.png";
import backgroundButtonIpad from "../../../assets/images/buttonEmpty/button-empty-ipad.png";

import styles from "./Button.module.scss";

interface IProps {
  text: string;
  alt: string;
  callback?: () => void;
}

const Button = ({ text, alt, callback }: IProps): ReactElement => (
  <div className={styles.contentButton} onClick={callback}>
    <img
      src={backgroundButtonWeb}
      className={styles.backgroundButtonWeb}
      alt={alt}
      role="button"
    />
    <img
      src={backgroundButtonIpad}
      className={styles.backgroundButtonIpad}
      alt={alt}
      role="button"
    />
    <div className={styles.content}>
      <p>{text}</p>
    </div>
  </div>
);

export default Button;
