import apiBackendClient from "../utils/apiBackendClient";

import { SIGNATURES_OPEN } from "../constants";

const getSignatureOpen = (
  address: string,
  toyId: number,
  familyName: string
) => {
  const body = {
    address,
    toyId,
    familyName,
  };

  try {
    const data = apiBackendClient.post(SIGNATURES_OPEN, body);

    return data;
  } catch (e) {
    throw e;
  }
};

const methods = {
  getSignatureOpen,
};

export default methods;
