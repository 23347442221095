import { ReactElement, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./MobileMenu.module.scss";
import closeButtonMobile from "../../assets/images/mobile-menu/close-button-mobile.png";
import closeButtonIpad from "../../assets/images/mobile-menu/close-button-ipad.png";
import ButtonNavigation from "../Buttons/ButtonNavigation";
import {
  FAQ,
  HOME,
  MINT,
  MY_COLLECTION,
  RAREPAPER_URL,
  ROADMAP,
} from "../../constants";
import useScrollLock from "../../hooks/useScrollLock";
import { IWeb3ContextProps, Web3Context } from "../../context/web3Context";

import useTotalToken from "../../hooks/contracts/useTotalToken";

import { useContractContext } from "../../context/contractContext";

interface IProps {
  setShowMenu: (open: boolean) => void;
}

const MobileMenu = ({ setShowMenu }: IProps): ReactElement => {
  const { unlockScroll } = useScrollLock();
  const location = useLocation();
  const { web3, walletAddress, connectWallet } = useContext(
    Web3Context
  ) as IWeb3ContextProps;

  const { toysTotalToken, toysMintedTotal } = useContractContext();

  const { totalSupply, totalTokens } = useTotalToken(web3);

  const connectWithMetamaskOrWalletConnect = async () => {
    if (!walletAddress) {
      await connectWallet();
    }
  };

  const closeMenu = () => {
    setShowMenu(false);
    unlockScroll();
  };

  useEffect(() => {
    totalTokens();
    totalSupply();
  }, [toysTotalToken, toysMintedTotal]);

  return (
    <div className={styles.backdrop}>
      <div className={styles.closeButtonContainer}>
        <img
          src={closeButtonMobile}
          alt="Close"
          className={styles.closeButtonMobile}
          onClick={() => {
            setShowMenu(false);
            unlockScroll();
          }}
        />
        <img
          src={closeButtonIpad}
          alt="Close"
          className={styles.closeButtonIpad}
          onClick={() => {
            setShowMenu(false);
            unlockScroll();
          }}
        />
      </div>
      <div className={styles.menuContainer}>
        <Link to={HOME}>
          {location.pathname === HOME ? (
            <p className={styles.itemSelected} onClick={closeMenu}>
              Home
            </p>
          ) : (
            <p className={styles.item} onClick={closeMenu}>
              Home
            </p>
          )}
        </Link>
        <a href={RAREPAPER_URL} target="_blank" rel="noreferrer">
          <p className={styles.item}>Rarepaper</p>
        </a>
        <Link to={ROADMAP}>
          {location.pathname === ROADMAP ? (
            <p className={styles.itemSelected} onClick={closeMenu}>
              Roadmap
            </p>
          ) : (
            <p className={styles.item} onClick={closeMenu}>
              Roadmap
            </p>
          )}
        </Link>
        <Link to={FAQ}>
          {location.pathname === FAQ ? (
            <p className={styles.itemSelected} onClick={closeMenu}>
              FAQ
            </p>
          ) : (
            <p className={styles.item} onClick={closeMenu}>
              FAQ
            </p>
          )}
        </Link>
        {(toysTotalToken > toysMintedTotal ||
          !toysTotalToken ||
          !toysMintedTotal) && (
            <Link to={MINT}>
              {location.pathname === MINT ? (
                <p className={styles.itemSelected} onClick={closeMenu}>
                  MINT
                </p>
              ) : (
                <p className={styles.item} onClick={closeMenu}>
                  MINT
                </p>
              )}
            </Link>
          )}
        {!walletAddress ? (
          <ButtonNavigation
            text="CONNECT"
            alt="connect"
            callback={connectWithMetamaskOrWalletConnect}
          />
        ) : (
          <Link to={MY_COLLECTION}>
            <ButtonNavigation
              text="MY COLLECTION"
              alt="my collection"
              callback={closeMenu}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
