import { ReactElement } from "react";

import Header from "../Header";

import error from "../../assets/images/home/404.svg";

import styles from "./Error404.module.scss";

const Error404 = (): ReactElement => (
  <div className={styles.error}>
    <Header />
    <img src={error} className={styles.error404} alt="Error 404" />
    <p className={styles.text}>Out of the Rareverse</p>
    <p className={styles.subtext}>
      Hmm... No fun or candies around here. Better get back!
    </p>
  </div>
);

export default Error404;
