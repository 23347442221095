import { useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import { TOY_CONTRACT_ADDRESS } from "../../constants/env";
import secondDropContractAbi from "../../contracts/SecondDropAbi";
import { useContractContext } from "../../context/contractContext";
import {
  EActionTypes,
  ETransactionStatus,
  EContractTransactionStatus,
} from "../../constants/enums";

import { IStateContractProps, IOpenSignatureProps } from "../../types";

type TUseContract = {
  open: () => void;
};

let secondDropContract: Web3["givenProvider"];

const useContractOpenToy = (
  web3: Web3 | null,
  walletAddress: string,
  openSignature: IOpenSignatureProps
): TUseContract => {
  const { setStateOpenToyContract } = useContractContext();

  useEffect(() => {
    if (web3) {
      secondDropContract = new web3.eth.Contract(
        secondDropContractAbi as AbiItem[],
        TOY_CONTRACT_ADDRESS
      );
    }
  }, [web3]);

  const handleState = (status: IStateContractProps) => {
    setStateOpenToyContract(status);
  };

  const open = async () => {
    await secondDropContract.methods
      .open(
        openSignature.tokenId,
        openSignature.fromTimestamp,
        openSignature.type,
        openSignature.size,
        openSignature.familyName,
        openSignature.signature
      )
      .send({ from: walletAddress })
      .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) => {
        const status = {
          type: EActionTypes.TRANSACTION_HASH,
          payload: {
            txId: txId as string,
            status: ETransactionStatus.IN_PROGRESS,
          },
        };

        handleState(status);
      })
      .once(EContractTransactionStatus.ERROR, ({ message }: Error) => {
        const error = {
          type: EActionTypes.ERROR,
          payload: {
            error: { message: message as string },
            status: ETransactionStatus.ERROR,
          },
        };

        handleState(error);
      })
      .once(EContractTransactionStatus.CONFIRMATION, () => {
        handleState({
          type: EActionTypes.CONFIRMATION,
          payload: {
            status: ETransactionStatus.SUCCESS,
          },
        });
      });
  };

  return {
    open,
  };
};

export default useContractOpenToy;
