import { ReactElement, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import Error404 from "../Error404";
import NavigationBar from "../NavigationBar";
import Home from "../HomeContainer/Home";
import Footer from "../Footer";
import Rarepaper from "../Rarepaper";
import ProductPet from "../ProductPet";
import Roadmap from "../Roadmap";
import MintStageThree from "../MintContainerStageThree";
import MyCollecion from "../MyCollection";
import Product from "../Product";
import Faq from "../Faq";
import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";

import {
  HOME,
  FAQ,
  MY_COLLECTION,
  MY_COLLECTION_PRODUCT,
  MY_COLLECTION_PETS_PRODUCT,
  RAREPAPER,
  ROADMAP,
  MINT,
  GTM_ID,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
} from "../../constants";
import ScrollToTop from "../../utils/scrollToTop";

import Web3ContextProvider from "../../context/web3Context";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const App = (): ReactElement => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const loader = document.querySelector("#loader-container");

  useEffect(() => {
    const fakeRequest = () => {
      return new Promise<void>((resolve) => setTimeout(() => resolve(), 4000));
    };

    fakeRequest().then(() => {
      if (loader) {
        setHasLoaded(true);
      }
    });

    if (hasLoaded) {
      loader?.classList.replace("showLoader", "hideLoader");
    } else {
      loader?.classList.replace("hideLoader", "showLoader");
    }
  }, [loader, hasLoaded]);

  return (
    <Web3ContextProvider>
      <NavigationBar />
      <ScrollToTop />
      <Switch>
        <Route path={RAREPAPER} component={Rarepaper} exact />
        <Route path={ROADMAP} component={Roadmap} exact />

        <Route path={MINT} component={MintStageThree} exact />

        <Route
          path={TERMS_AND_CONDITIONS}
          component={TermsAndConditions}
          exact
        />
        <Route path={PRIVACY_POLICY} component={PrivacyPolicy} exact />
        <Route path={MY_COLLECTION} component={MyCollecion} exact />
        <Route path={MY_COLLECTION_PRODUCT} component={Product} exact />
        <Route path={MY_COLLECTION_PETS_PRODUCT} component={ProductPet} exact />
        <Route path={FAQ} component={Faq} exact />
        <Route path={HOME} component={Home} exact />
        <Route component={Error404} />
      </Switch>
      <Footer />
    </Web3ContextProvider>
  );
};

export default App;
