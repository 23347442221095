import apiBackendClient from "../utils/apiBackendClient";

import { PETS } from "../constants";

const getPetById = (id: string) => apiBackendClient.get(`${PETS}/${id}`);

const getPetsForOwner = (owner: string, limit: number, offset: number) =>
  apiBackendClient.get(PETS, {
    params: { owner, limit: limit, offset: offset },
  });

const methods = {
  getPetById,
  getPetsForOwner,
};

export default methods;
