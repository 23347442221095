import { ReactElement } from "react";

import styles from "./Success.module.scss";

import myCollectionButton from "../../../../assets/images/my-collection/my-collection-button.svg";
import { useContractContext } from "../../../../context/contractContext";
import { ETHERSCAN_URL, MY_COLLECTION } from "../../../../constants";
import useScrollLock from "../../../../hooks/useScrollLock";
import { Link } from "react-router-dom";

const Response = (): ReactElement => {
  const { stateOpenToyContract } = useContractContext();
  const txId: string = stateOpenToyContract.payload.txId as string;
  const { unlockScroll } = useScrollLock();

  return (
    <div className={styles.content} data-testid="content-success">
      <p className={styles.text}>Transaction in Progress</p>
      <a
        href={`${ETHERSCAN_URL}/tx/${txId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <p className={styles.url}>{`${ETHERSCAN_URL}/tx/${txId}`}</p>
      </a>

      <p>It may take up to 10 blocks to confirm the transaction</p>
      <div className={styles.contentButton}>
        <Link to={MY_COLLECTION}>
          <img
            src={myCollectionButton}
            alt="My collection"
            onClick={() => unlockScroll()}
          />
        </Link>
      </div>
    </div>
  );
};

export default Response;
