import { ReactElement } from "react";
import styles from "./Roadmap.module.scss";

import rareRoadmap from "./../../../assets/images/home/rare-roadmap.svg";
import { ROADMAP } from "./../../../constants";
import { Link } from "react-router-dom";

const Roadmap = (): ReactElement => (
  <div className={styles.roadmap}>
    <Link to={ROADMAP} data-testid="roadmap">
      <img src={rareRoadmap} alt="roadmap" className={styles.image} />
    </Link>
  </div>
);

export default Roadmap;
