import apiBackendClient from "../utils/apiBackendClient";

import { SIGNATURES_MINT } from "../constants";

const getSignature = (address: string, amount: number) => {
  const body = {
    address,
    amount,
  };

  try {
    const data = apiBackendClient.post(SIGNATURES_MINT, body);

    return data;
  } catch (e) {
    throw e;
  }
};

const methods = {
  getSignature,
};

export default methods;
