import { ReactElement, useEffect, useContext } from "react";

import styles from "./Home.module.scss";
import Header from "./../Header";
import StageThree from "../StageThree/StageThree";
import ToysSoldOut from "../ToysSoldOut";
import MeetTheToys from "./../MeetTheToys";
import MeetThePets from "./../MeetThePets";
import MintYourPets from "./../MintYourPets";
import WenSale from "./../WenSale";
import TheRareTeam from "./../TheRareTeam";
import Roadmap from "./../Roadmap";
import { IWeb3ContextProps, Web3Context } from "../../../context/web3Context";

import useTotalToken from "../../../hooks/contracts/useTotalToken";
import {
  ContractContextProvider,
  useContractContext,
} from "../../../context/contractContext";

const Home = (): ReactElement => {
  const { web3 } = useContext(Web3Context) as IWeb3ContextProps;

  const { toysTotalToken, toysMintedTotal } = useContractContext();

  const { totalSupply, totalTokens } = useTotalToken(web3);

  useEffect(() => {
    totalTokens();
    totalSupply();
  }, [toysTotalToken, toysMintedTotal]);

  return (
    <div className={styles.home}>
      <Header />

      {(toysTotalToken > toysMintedTotal ||
        !toysTotalToken ||
        !toysMintedTotal) && <StageThree />}

      {toysTotalToken <= toysMintedTotal &&
        !!toysTotalToken &&
        !!toysMintedTotal && <ToysSoldOut />}

      <MeetTheToys />

      <MeetThePets />

      <MintYourPets />

      <WenSale />

      <TheRareTeam />

      <Roadmap />
    </div>
  );
};

const HomeProvider = () => (
  <ContractContextProvider>
    <Home />
  </ContractContextProvider>
);

export default HomeProvider;
