import { ReactElement } from "react";
import styles from "./TheRareTeam.module.scss";
import TeamMember from "./TeamMember";

import theRareTeam from "../../../assets/images/home/the-rareteam.svg";

import nftStudios from "./../../../assets/images/home/presentation-1.png";
import jimi from "./../../../assets/images/home/presentation-3.png";
import mauri from "./../../../assets/images/home/presentation-4.png";

const TheRareTeam = (): ReactElement => (
  <>
    <img src={theRareTeam} alt="The Rareteam" className={styles.title} />

    <div className={styles.teamMembers}>
      <TeamMember
        image={nftStudios}
        alt={"NFT STUDIOS"}
        name={"NFT STUDIOS"}
        description={"Backbone"}
        link="https://twitter.com/nftstudios_io"
      />

      <TeamMember
        image={jimi}
        alt={"Jimi"}
        name={"JIMI"}
        description={"Art Master"}
        link="https://www.instagram.com/jimigrins"
      />

      <TeamMember
        image={mauri}
        alt={"Mauri"}
        name={"MAURI"}
        description={"Code Wizard"}
        link="https://www.instagram.com/mauro.cristy/"
      />
    </div>
  </>
);

export default TheRareTeam;
