import { ReactElement, useState } from "react";
import styles from "./Curtain.module.scss";
import stylesOpen from "./CurtainOpen.module.scss";

interface props {
  close?: boolean;
}
const Curtain = ({ close = true }: props): ReactElement => {
  const [hasAnimationEnded, setHasAnimationEnded] = useState(false);

  if (close) {
    return (
      <div className={styles.curtain}>
        <div className={styles.bottom}></div>
      </div>
    );
  }

  if (hasAnimationEnded) {
    return <></>;
  }

  return (
    <div
      className={stylesOpen.curtain}
      onAnimationEnd={() => setHasAnimationEnded(true)}
      onAnimationIteration={() => setHasAnimationEnded(true)}
    >
      <div className={stylesOpen.bottom}></div>
    </div>
  );
};

export default Curtain;
