import { ReactElement, FormEvent, useState, ChangeEvent } from "react";

import styles from "./Mint.module.scss";

import Header from "../../Header";
import titleMintYourToys from "../../../assets/images/mint/mint-your-toys.svg";
import subtitleStageThree from "../../../assets/images/event-stages/public-mint.svg";
import minus from "../../../assets/images/mint/minus.png";
import plus from "../../../assets/images/mint/plus.png";
import priceLine from "../../../assets/images/mint/price-line.png";
import amountLine from "../../../assets/images/mint/amount-line.png";
import toysGif from "../../../assets/images/mint/mint-page-gif-mobile.gif";
import ModalMint from "./Modal";
import { IStateContractProps } from "../../../types";
import { CHAIN_ID } from "../../../constants/env";

import ButtonMintStage from "../../Buttons/ButtonMintStage";
import ButtonLargeDisabled from "../../Buttons/ButtonLargeDisabled";

interface IProps {
  handleOnSubmit: (e: FormEvent) => void;
  redirectMyCollection: () => void;
  handleOpenModal: () => void;
  setOpenModal: (value: boolean) => void;
  setStateContract: (stateContract: IStateContractProps) => void;
  handleSubstract: () => void;
  handleAdd: () => void;
  toys: {
    id: number;
    image: string;
    name: string;
  }[];
  openModal: boolean;
  toysMintedTotal: number;
  toysTotalToken: number;
  DEFAULT_MINT_QUANTITY: number;
  amount: number;
  setAmount: (value: number) => void;
  PRICE: Number;
  maxPerTransaction: number;
  total: Number;
  stateContract: IStateContractProps;
  walletAddress: string;
  connectWallet: () => Promise<void>;
  chainId: number;
}

const Mint = ({
  redirectMyCollection,
  handleOpenModal,
  setOpenModal,
  setStateContract,
  handleOnSubmit,
  toys,
  amount,
  setAmount,
  PRICE,
  total,
  stateContract,
  openModal,
  walletAddress,
  toysTotalToken,
  toysMintedTotal,
  connectWallet,
  handleAdd,
  handleSubstract,
  maxPerTransaction,
  DEFAULT_MINT_QUANTITY,
  chainId,
}: IProps): ReactElement => {
  const [showQuantityInput, setShowQuantityInput] = useState(false);

  const handleChange = (value: number) => {
    if (value > maxPerTransaction) {
      setAmount(maxPerTransaction);
      return;
    }
    setAmount(value);
  };

  const handleSubmit = () => {
    setShowQuantityInput(false);
  };

  return (
    <>
      <div className={styles.mint}>
        <Header />
        <img
          className={styles.titleMint}
          src={titleMintYourToys}
          alt="Mint Your Toys"
        />
        <img
          className={styles.subtitleMint}
          src={subtitleStageThree}
          alt="Stage Three: Public"
        />
        <div className={styles.contentText}>
          <p className={styles.text}>
            Welcome to the Public Mint! You can mint as many Raregotchi TOYS as you want,
            with no limit. Owning a TOY allows you to unlock many benefits in
            the Rareverse. Remember that minting is random, so you can't choose
            what TOY you get.
          </p>
        </div>
        <div className={styles.contentCard}>
          <div className={styles.contentGif}>
            <img src={toysGif} className={styles.gif} alt="Pets" />
          </div>
          <div className={styles.toys}>
            {toys.map(({ id, image, name }) => (
              <div className={styles.card} key={id}>
                <img className={styles.image} src={image} alt={name} />
                <div className={styles.name}>{name}</div>
              </div>
            ))}
          </div>
          <div className={styles.contentMint}>
            <div className={styles.contentAmount}>
              <img
                onClick={handleSubstract}
                className={
                  amount <= DEFAULT_MINT_QUANTITY
                    ? styles.minusDisabled
                    : styles.minus
                }
                src={minus}
                alt="decrease"
                data-testid="minus-button"
                role="button"
              />
              <img className={styles.amountLine} src={amountLine} alt="" />
              {!showQuantityInput ? (
                <span
                  className={styles.amount}
                  data-test-id="amount"
                  onClick={() => setShowQuantityInput(true)}
                >
                  {amount}
                </span>
              ) : (
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    value={amount}
                    className={styles.amount}
                    data-test-id="amount"
                    onChange={(event: ChangeEvent) =>
                      handleChange(
                        parseInt((event.target as HTMLTextAreaElement).value) ||
                        1
                      )
                    }
                  />
                </form>
              )}
              <img className={styles.amountLine} src={amountLine} alt="" />
              <img
                onClick={handleAdd}
                className={
                  amount === maxPerTransaction
                    ? styles.plusDisabled
                    : styles.plus
                }
                src={plus}
                alt="increase"
                data-testid="plus-button"
                role="button"
              />
            </div>
            <div className={styles.contentPrice}>
              <span className={styles.priceUnit}>{PRICE} ETH</span>
              <img className={styles.priceLine} src={priceLine} alt="" />
              <span className={styles.priceTotal}>{total} ETH</span>
            </div>
          </div>
          <div className={styles.contentButton} data-testid="mint">
            {
              walletAddress ? (
                chainId === Number(CHAIN_ID) ? (
                  toysMintedTotal === toysTotalToken ? (
                    <ButtonLargeDisabled text="SOLD OUT!" alt="sold out" />
                  ) : (
                    <ButtonMintStage
                      text="MINT"
                      alt="button mint"
                      callback={handleOpenModal}
                    />
                  )
                ) : (
                  <ButtonMintStage
                    text="INCORRECT NETWORK"
                    alt="incorrect network"
                    disabled
                  />
                )
              ) : (
                <ButtonMintStage
                  text="CONNECT WALLET"
                  alt="button connect wallet"
                  callback={connectWallet}
                />
              )}
          </div>
        </div>
      </div>
      <ModalMint
        open={openModal}
        setOpenModal={setOpenModal}
        amount={amount}
        total={total}
        handleOnSubmit={handleOnSubmit}
        stateContract={stateContract}
        redirectMyCollection={redirectMyCollection}
        setStateContract={setStateContract}
      />
    </>
  );
};

export default Mint;
