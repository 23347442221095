import { ReactElement } from "react";

import styles from "./PetCardsContainer.module.scss";

interface IProps {
  reverseBackground?: boolean;
  children: React.ReactNode;
}

const PetCardsContainer = ({
  reverseBackground,
  children,
}: IProps): ReactElement => (
  <div
    className={`${styles.petCardsContainer} ${
      reverseBackground && styles.reverseBackground
    }`}
  >
    {children}
  </div>
);

export default PetCardsContainer;
