import { ReactElement } from "react";

import styles from "./Success.module.scss";

import myCollectionButton from "../../../../../assets/images/mint/my-collection.svg";

import { ETHERSCAN_URL } from "../../../../../constants";
import { IStateContractProps } from "../../../../../types";
import useScrollLock from "../../../../../hooks/useScrollLock";

interface IProps {
  redirectMyCollection: () => void;
  stateContract: IStateContractProps;
}

const Success = ({
  redirectMyCollection,
  stateContract,
}: IProps): ReactElement => {
  const txId: string = stateContract.payload.txId as string;
  const { unlockScroll } = useScrollLock();

  return (
    <div className={styles.content}>
      <p>Transaction in Progress</p>
      <a
        href={`${ETHERSCAN_URL}/tx/${txId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <p className={styles.url}>{`${ETHERSCAN_URL}/tx/${txId}`}</p>
      </a>
      <p>It may take a moment to confirm the transaction</p>
      <p>Back to</p>
      <div className={styles.contentButton}>
        <img
          src={myCollectionButton}
          alt="my collection"
          onClick={() => {
            redirectMyCollection();
            unlockScroll();
          }}
          role="button"
        />
      </div>
    </div>
  );
};

export default Success;
