import { ReactElement, useEffect, useState } from "react";

import styles from "./Modal.module.scss";

import cross from "../../../assets/images/my-collection/cross.png";
import Modal from "react-modal";
import MessageWarning from "./MessageWarning";
import SelectName from "./SelectName";
import OpenToy from "./OpenToy";
import Error from "./Error";
import Success from "./Success";

import { EStepsOpenToy } from "../../../types";
import useScrollLock from "../../../hooks/useScrollLock";
import { useContractContext } from "../../../context/contractContext";
import { ETransactionStatus } from "../../../constants/enums";
import ToyOpening from "../../ToyOpening";
import { Redirect } from "react-router-dom";
import { MY_COLLECTION } from "../../../constants";
import apiBackendClient from "../../../utils/apiBackendClient";

interface IProps {
  open: boolean;
  setOpenModal: (value: boolean) => void;
  toyId: number;
}

const ModalMSG = ({ open, setOpenModal, toyId }: IProps): ReactElement => {
  const { lockScroll, unlockScroll } = useScrollLock();
  const { stateOpenToyContract, setStateOpenToyContract } =
    useContractContext();

  const [step, setStep] = useState(EStepsOpenToy.FIRST);
  const [familyName, setFamilyName] = useState("");
  const [validation, setValidation] = useState("");
  const [redirectToCollection, setRedirectToCollection] = useState(false);

  const handleClose = () => {
    setStep(EStepsOpenToy.FIRST);
    setOpenModal(false);
  };

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);

  useEffect(() => {
    setStateOpenToyContract({
      type: "CALLING_CONTRACT",
      payload: {
        status: "WAITING_CONFIRMATION",
      },
    });
    setFamilyName("");
  }, [open]);

  useEffect(() => {
    if (stateOpenToyContract.payload.status === ETransactionStatus.SUCCESS) {
      const interval = setInterval(() => {
        apiBackendClient.get(`/toys/${toyId}/pets`).then(({ data }) => {
          if (data.length > 0) {
            clearInterval(interval);
            setRedirectToCollection(true);
          }
        });
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [stateOpenToyContract.payload.status, toyId]);

  if (redirectToCollection) {
    unlockScroll();
    return <Redirect to={`${MY_COLLECTION}?open-toy`} />;
  }

  if (
    step === EStepsOpenToy.FOURTH &&
    (stateOpenToyContract.payload.status === ETransactionStatus.IN_PROGRESS ||
      stateOpenToyContract.payload.status === ETransactionStatus.SUCCESS)
  ) {
    window.scrollTo(0, 0);
    return (
      <>
        <ToyOpening close={true} />
        <Modal
          isOpen={open}
          className={styles.modal}
          ariaHideApp={false}
          overlayClassName={styles.overlayTransactionInProgress}
        >
          <div className={styles.modalContentTransactionInProgress}>
            <div className={styles.content}>
              <Success />
            </div>
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={open}
        className={styles.modal}
        ariaHideApp={false}
        overlayClassName={styles.overlay}
        shouldCloseOnEsc={true}
        onRequestClose={handleClose}
      >
        <div className={styles.modalContent}>
          <div className={styles.contentCross}>
            <img src={cross} alt="close" onClick={handleClose} />
          </div>
          <div className={styles.content}>
            {step === EStepsOpenToy.FIRST && (
              <MessageWarning
                handleClose={handleClose}
                setStep={setStep}
                step={step}
              />
            )}

            {step === EStepsOpenToy.SECOND && (
              <SelectName
                setStep={setStep}
                step={step}
                handleClose={handleClose}
                familyName={familyName}
                setFamilyName={setFamilyName}
                validation={validation}
                setValidation={setValidation}
              />
            )}

            {step === EStepsOpenToy.THIRD && (
              <OpenToy
                step={step}
                setStep={setStep}
                validation={validation}
                familyName={familyName}
                toyId={toyId}
              />
            )}

            {step === EStepsOpenToy.FOURTH &&
              stateOpenToyContract.payload.status ===
                ETransactionStatus.ERROR && <Error />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalMSG;
