export const APP_NAME: string = process.env.REACT_APP_NAME ?? "";
export const APP_AUTHOR: string = process.env.REACT_APP_AUTHOR ?? "";

export const BACKEND_API_BASE_URL =
  process.env.REACT_APP_BACKEND_API_BASE ?? "";
export const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_URL;
export const RAREPAPER_URL = process.env.REACT_APP_RAREPAPER_URL;

export const INFURA_ID = process.env.REACT_APP_INFURA_ID;

export const FIRST_DROP_CONTRACT_ADDR =
  process.env.REACT_APP_FIRST_DROP_CONTRACT_ADDR ?? "";

export const TOY_CONTRACT_ADDRESS =
  process.env.REACT_APP_TOY_CONTRACT_ADDRESS ?? "";

export const PET_CONTRACT_ADDRESS =
  process.env.REACT_APP_PET_CONTRACT_ADDRESS ?? "";

export const STAGE_ONE_TIMESTAMP: number = parseInt(
  process.env.REACT_APP_STAGE_ONE_TIMESTAMP ?? "0"
);
export const STAGE_TWO_TIMESTAMP: number = parseInt(
  process.env.REACT_APP_STAGE_TWO_TIMESTAMP ?? "0"
);
export const STAGE_THREE_TIMESTAMP: number = parseInt(
  process.env.REACT_APP_STAGE_THREE_TIMESTAMP ?? "0"
);

export const GTM_ID: string = process.env.REACT_APP_GTM_ID ?? "";

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
