import { ReactElement } from "react";

import styles from "./StageThree.module.scss";
import stageTwoTitle from "../../../assets/images/mint/mint-your-toys.svg";
import subtitle from "../../../assets/images/event-stages/stage-now-3.svg";
import { MINT } from "../../../constants";
import Button from "../../Buttons/Button";
import { Link } from "react-router-dom";

const StageOne = (): ReactElement => {
  return (
    <>
      <img
        className={styles.eventTitle}
        src={stageTwoTitle}
        alt="Mint your pets"
      />
      <img src={subtitle} className={styles.toySale} alt="OG Raffle" />
      <div className={styles.contentText}>
        <p className={styles.description}>
          There are only 3000 TOYS in the Raregotchi collection and many benefits for holders to unlock and enjoy.
        </p>
        <p className={styles.description}>
          Connect your wallet and mint your TOYS now.
          <br />
          Your adventure in the Rareverse is about to start!
        </p>
      </div>
      <Link to={MINT} data-testid="go-mint-three">
        <Button text="GO MINT" alt="button go mint" />
      </Link>
    </>
  );
};

export default StageOne;
