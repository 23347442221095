import { useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import { FIRST_DROP_CONTRACT_ADDR } from "../../constants/env";
import FirstDropContractAbi from "../../contracts/FirstDropAbi";
import { useContractContext } from "../../context/contractContext";

type TUseTotalsToken = {
  totalSupply: () => void;
  totalTokens: () => void;
};

let firstDropContract: Web3["givenProvider"];

const useTotalToken = (web3: Web3 | null): TUseTotalsToken => {
  const { setToysMintedTotal, setToysTotalToken } = useContractContext();

  useEffect(() => {
    if (web3) {
      firstDropContract = new web3.eth.Contract(
        FirstDropContractAbi as AbiItem[],
        FIRST_DROP_CONTRACT_ADDR
      );
    }
  }, [web3]);

  const handleToysMintedTotal = (totalSupply: number) => {
    setToysMintedTotal(totalSupply);
  };

  const handleToysTotalToken = (totalTokens: number) => {
    setToysTotalToken(totalTokens);
  };

  const totalSupply = async () => {
    const totalSupply = await firstDropContract?.methods.totalSupply().call();

    handleToysMintedTotal(parseInt(totalSupply));
  };

  const totalTokens = async () => {
    const totalTokens = await firstDropContract?.methods.totalTokens().call();

    handleToysTotalToken(parseInt(totalTokens));
  };

  return {
    totalSupply,
    totalTokens,
  };
};

export default useTotalToken;
