import { ReactElement } from "react";
import styles from "./WenSale.module.scss";

import toyPackages from "./../../../assets/images/home/toy-packages.png";
import ButtonLarge from "../../Buttons/ButtonLarge";

const WenSale = (): ReactElement => (
  <div className={styles.contentPreSale}>
    <div className={styles.contentTextPreSale}>
      <a
        href="https://discord.gg/raregotchi"
        data-testid="join-our-community-second"
      >
        <ButtonLarge text="JOIN OUR COMMUNITY" alt="join our community" />
      </a>
    </div>
    <div className={styles.contentImageToyPackages}>
      <img
        src={toyPackages}
        alt="toy packages"
        className={styles.imageToyPackages}
      />
    </div>
  </div>
);

export default WenSale;
