import { ReactElement } from "react";

import styles from "./Roadmap.module.scss";

import roadmap from "../../assets/images/roadmap/roadmap.svg";
import guyRed from "../../assets/images/roadmap/guy-1.png";
import guySkyBlue from "../../assets/images/roadmap/guy-2.png";
import guyYellow from "../../assets/images/roadmap/guy-3.png";
import Header from "../Header";

const Roadmap = (): ReactElement => (
  <div className={styles.roadmap}>
    <Header />
    <img className={styles.titleRoadmap} src={roadmap} alt="Title Roadmap" />
    <div className={styles.content}>
      <div className={styles.contentImage}>
        <div className={styles.imageWorm}>
          <img className={styles.guyFirst} src={guyRed} alt="guy" />
          <img className={styles.guySecond} src={guyYellow} alt="guy" />
          <img className={styles.guyThird} src={guySkyBlue} alt="guy" />
          <img className={styles.guyFourth} src={guyRed} alt="guy" />
          <img className={styles.guyFifth} src={guyYellow} alt="guy" />
        </div>
      </div>
      <div className={styles.contentText}>
        <p className={styles.title}>OG TOYS SALE - Q2 2022</p>
        <p className={styles.titleResponsive}>
          OG TOYS SALE - <br /> Q2 2022
        </p>

        <p className={styles.text}>
          Launch first generation of founder TOYS: School Kid, Snow Cone, and
          Alien Baby.
          <br />
          <br />
          TOY owners will be able to fund their lineage, vote on the RareDAO and
          access many more benefits as the Rareverse expands.
        </p>
        <p className={styles.title}>OG PET MINTING - Q3 2022</p>
        <p className={styles.titleResponsive}>
          OG PET MINTING - <br /> Q3 2022
        </p>
        <p className={styles.text}>
          Opening the digital package will allow TOY owners to mint their OG
          PETS.
          <br />
          <br />
          All 9.999 OG PETS (Obtainable by minting, not breeding) will roll
          special sets and rarity tiers making them all unique and highly
          collectible. These traits will not be passed through breeding.
          <br />
          <br />
          In the near future, PET owners will be able to take pets on different
          adventures and earn tokens and NFTs, expand the lineage by breeding
          their pets, and get exciting new combinations with millions of
          different pets to collect!
        </p>
        <p className={styles.title}>
          TOKEN AND HOUSES - <br /> Q4 2022
        </p>
        <p className={styles.titleResponsive}>
          TOKEN CREATION <br /> AND MINIGAME - <br /> Q4 2022
        </p>
        <p className={styles.text}>
          Launch of our In-Game Token: Rarepeaches. Users will earn these tokens
          by playing our different P4F Games. Users will use Rarepeaches to
          breed new PETS, buy resources, and upgrade their House and other
          in-game assets.
          <br />
          <br />
          Do you want more? TOY owners will be able to get a free claim of a
          Raregotchi House! Houses will allow players to access the Raregotchi
          games and earn rewards. Houses will come in different types & rarity
          tiers, and will also be obtainable via public sale.
          <br />
          <br />
          Additionally, TOY owners will be able to claim a physical vinyl
          version of their NFT TOY, and get it shipped anywhere in the world.
          <br />
          <br />
          Amazing, right? Show the Rareverse some love and tell your friends
          about it!
        </p>
        <p className={styles.title}>
          MINIGAME, BREEDING, PVP BATTLES & MORE - 2023
        </p>
        <p className={styles.titleResponsive}>
          MINIGAME, BREEDING, <br /> PVP BATTLES & MORE -<br /> 2023
        </p>
        <p className={styles.text}>
          Release of the first P4F game of the Rareverse!
          <br />
          <br />
          House owners will be able to activate their Raregotchi House,
          customize it, and perform different actions to earn rewards. Owning a
          Raregotchi PET gives users a boost in earnings!
          <br />
          <br />
          Breeding goes live and you can start seriously using your hoarded
          in-game tokens. New PETS are great presents for your non-hodler
          friends!
          <br />
          <br />
          Are you more of a PVP person? Are you entirely sure your team is
          better than the rest? Cool! Now prove it at the arena. Prepare to
          engage in competitive battles against other players.
          <br />
          <br />
          We are also implementing the Raregotchi Marketplace, an in-game
          platform where you can buy, sell, and trade game assets and currencies
          with other players.
        </p>
        <p className={styles.title}>DOWN THE ROAD</p>
        <p className={styles.titleResponsive}>DOWN THE ROAD</p>
        <p className={styles.text}>
          Looking at the speed at which the Metaverse moves it is really hard to
          make a more accurate roadmap. There are many things we want to do but
          are focusing on creating an awesome project now.
          <br />
          <br />
          However, what lays beyond the horizon for the Rareverse is this:
          Develop governance rules, Community Fund and hard token, staking
          protocols and further DAO discussion with the community.
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>
);

export default Roadmap;
