import { ReactElement, useEffect } from "react";

import styles from "./Modal3D.module.scss";

import Modal from "react-modal";
import useScrollLock from "../../../hooks/useScrollLock";

interface IProps {
  open: boolean;
  setOpenModal: (value: boolean) => void;
  model3d: string;
  type: string;
  size: string;
}

const Modal3D = ({
  open,
  setOpenModal,
  model3d,
  type,
  size,
}: IProps): ReactElement => {
  const { lockScroll, unlockScroll } = useScrollLock();

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    open ? lockScroll() : unlockScroll();
  }, [open, lockScroll, unlockScroll]);

  return (
    <Modal
      isOpen={open}
      className={styles.modal}
      ariaHideApp={false}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={handleClose}
    >
      <div className={styles.modalContent}>
        <div className={styles.content}>
          <model-viewer
            src={model3d?.replace("null", type).replace("null", size)}
            shadow-intensity="1"
            shadow-softness="1"
            exposure="1.6"
            ar
            ar-modes="webxr scene-viewer quick-look"
            camera-controls
            alt="A 3D model carousel"
            environment-image="https://storage.googleapis.com/whitepaper/env.hdr"
            camera-orbit="0deg 85deg 11.5m"
          />
        </div>
      </div>
    </Modal>
  );
};

export default Modal3D;
