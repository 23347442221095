import styles from "./Footer.module.scss";

import { Link } from "react-router-dom";

import footerWeb from "../../assets/images/footer-web.png";
import twitter from "../../assets/images/comingSoon/twitter.png";
import discord from "../../assets/images/comingSoon/discord.png";
import openSea from "../../assets/images/comingSoon/opensea-logo.png";
import medium from "../../assets/images/comingSoon/medium.png";
import nftStudios from "../../assets/images/home/nft-studios.png";
import emenioLogo from "../../assets/images/home/emenio.png";
import { FAQ, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../../constants";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerInfoContainer}>
        <div className={styles.listContainer}>
          <p className={styles.title}>RESOURCES</p>
          <ul className={styles.list}>
            <li>
              <a
                href="https://www.nftstudios.io/"
                target="_blank"
                rel="noreferrer noopener"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/raregotchi"
                target="_blank"
                rel="noreferrer noopener"
              >
                Need Help?
              </a>
            </li>
            <li>
              <a
                href="https://rarepaper.raregotchi.com/contracts/raregotchi-smart-contracts"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.resourcesItem}
              >
                Contracts
              </a>
            </li>
            <li>
              <Link to={FAQ} className={styles.resourcesItem}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to={TERMS_AND_CONDITIONS} className={styles.resourcesItem}>
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link to={PRIVACY_POLICY} className={styles.resourcesItem}>
                Privacy Policy
              </Link>
            </li>
          </ul>
          <h3>©2021 Raregotchi. All rights reserved.</h3>
        </div>
        <div className={styles.socialMediaListContainer}>
          <p className={styles.titleJoinUs}>JOIN US</p>
          <ul className={styles.socialMediaList}>
            <a
              href="https://discord.gg/raregotchi"
              target="_blank"
              rel="noreferrer noopener"
            >
              <li>
                <img
                  src={discord}
                  className={styles.discordImage}
                  alt="Discord"
                />
                <p>Discord</p>
              </li>
            </a>
            <a
              href="https://twitter.com/raregotchi"
              target="_blank"
              rel="noreferrer noopener"
            >
              <li>
                <img
                  src={twitter}
                  className={styles.twitterImage}
                  alt="Twitter"
                />
                <p>Twitter</p>
              </li>
            </a>
            <a
              href="https://opensea.io/collection/raregotchi"
              target="_blank"
              rel="noreferrer noopener"
            >
              <li>
                <img
                  src={openSea}
                  className={styles.openSeaImage}
                  alt="Opensea"
                />
                <p>Opensea</p>
              </li>
            </a>
            <a
              href="https://medium.com/raregotchi"
              target="_blank"
              rel="noreferrer noopener"
            >
              <li>
                <img
                  src={medium}
                  className={styles.openSeaImage}
                  alt="Medium"
                />
                <p>Medium</p>
              </li>
            </a>
          </ul>
        </div>
        <div className={styles.poweredByContainer}>
          <p className={styles.title}>POWERED BY</p>
          <a href="https://www.nftstudios.io/" target="_blank" rel="noreferrer">
            <img src={nftStudios} alt="NFT Studios" />
          </a>
          <a
            href="https://www.emenio.com/"
            className={styles.emenioLogo}
            target="_blank"
            rel="noreferrer"
          >
            <img src={emenioLogo} alt="emenio" />
          </a>
        </div>
      </div>
      <img
        src={footerWeb}
        className={styles.footerWeb}
        alt="Extended Footer"
      ></img>
    </div>
  );
};

export default Footer;
