import { Fragment, ReactElement } from "react";

import styles from "./Header.module.scss";

import header from "../../assets/images/header.png";
import headerMobile from "../../assets/images/header-mobile.png";

const Header = (): ReactElement => (
  <Fragment>
    <img className={styles.header} src={header} alt="" />
    <img className={styles.headerMobile} src={headerMobile} alt="" />
  </Fragment>
);

export default Header;
