import { ReactElement } from "react";

import styles from "./PrivacyPolicy.module.scss";
import titlePrivacyPolicy from "../../assets/images/privacyPolicy/privacy-policy.svg";

import Header from "../Header";

const PrivacyPolicy = (): ReactElement => (
  <div className={styles.privacyPolicy}>
    <Header />
    <img
      src={titlePrivacyPolicy}
      alt="Provicy Policy"
      className={styles.titlePrivacyPolicy}
    />

    <div className={styles.contentText}>
      <p>
        The Platform (“we” or “us”), accessible from, is committed to protecting
        your privacy. We have prepared this Privacy Policy to describe to you
        our practices regarding the Personal Data (as defined below) we collect
        from users of our website, located at, (the "Site") and online services
        ("Services"). If you see an undefined term in this Privacy Policy, it
        has the same definition as in our Terms of Service
        www.raregotchi.com/terms-of-use
      </p>

      <ul>
        <li className={styles.column}>
          <p>1</p>
          <p>
            We would like to clarify that the Platform does not generally retain
            Users' data but, where personal information of our Users reaches us,
            they will be treated in the manner illustrated below.
          </p>
        </li>
        <li className={styles.column}>
          <p>2</p>
          <p>
            Questions, Contacting Company, Reporting Violations. If you have any
            questions or concerns or complaints about our Privacy Policy or our
            data collection or processing practices, or if you want to report
            any security violations to us, please contact us at the following
            e-mail address: info@raregotchi.com
          </p>
        </li>
        <li className={styles.column}>
          <p>3</p>
          <p>
            User Consent. By submitting Personal Data through our Site or
            Services, you agree to the terms of this Privacy Policy and you
            expressly consent to the collection, use and disclosure of your
            Personal Data in accordance with this Privacy Policy. For conducting
            cryptocurrency transactions, we use third-party electronic wallet
            extensions such as (but not limited to) MetaMask; your interactions
            with MetaMask and/or any third-party electronic wallet extensions
            are governed by the applicable privacy policies. In the case of
            MetaMask, its privacy policy is available at
            https://metamask.io/privacy.html.
          </p>
        </li>
        <li className={styles.column}>
          <p>4</p>
          <p>
            A Note About Children. We do not intentionally gather Personal Data
            from visitors who are under the age of 13. If a child under 13
            submits Personal Data to Company and we learn that the Personal Data
            is the information of a child under 13, we will attempt to delete
            the information as soon as possible. If you believe that we might
            have any Personal Data from a child under 13, please contact us at
            the address indicated in Paragraph 1.
          </p>
        </li>
        <li className={styles.column}>
          <p>5</p>
          <p>
            A Note to Users Outside of the United States. If you are a non-U.S.
            user of the Site, by visiting the Site and providing us with data,
            you acknowledge and agree that your Personal Data may be processed
            for the purposes identified in the Privacy Policy. In addition, your
            Personal Data may be processed in the country in which it was
            collected and in other countries, including the United States, where
            laws regarding processing of Personal Data may be less stringent
            than the laws in your country. By providing your data, you consent
            to such transfer.
          </p>
        </li>
        <li className={styles.column}>
          <p>6</p>
          <p>
            Types of Data We Collect. "Personal Data" means data that allows
            someone to identify or contact you, including, for example, your
            name, e-mail address, as well as any other non-public information
            about you that is associated with or linked to any of the foregoing
            data. "Anonymous Data" means data, including aggregated and
            de-identified data, that is not associated with or linked to your
            Personal Data; Anonymous Data does not, by itself, permit the
            identification of individual persons. We collect Personal Data and
            Anonymous Data, as described below.
          </p>
        </li>
        <li className={styles.column}>
          <p>7</p>
          <p>
            Information You Provide to Us. If you provide us feedback or contact
            us via e-mail, we will collect your name and e-mail address, as well
            as any other content included in the e-mail, in order to send you a
            reply.
          </p>
        </li>
        <li className={styles.column}>
          <p>8</p>
          <p>
            We also collect other types of Personal Data that you provide to us
            voluntarily, such as your operating system and version, product
            registration number, and other requested information if you contact
            us via e-mail regarding support for the Services.
          </p>
        </li>
        <li className={styles.column}>
          <p>9</p>
          <p>
            Information Collected from You About Others. Information Collected
            from Third Party Companies. We may receive Personal and/or Anonymous
            Data about you from companies that provide our Services by way of a
            co-branded or private-labeled website or companies that offer their
            products and/or services on our Site. In particular, MetaMask
            provides us with your Ethereum address and certain other information
            you choose to share with MetaMask; and Civic provides us with an
            encrypted image of your government issued identification document
            and certain other information you authorize Civic to share with us
            for identity verification purposes. These third party companies may
            supply us with Personal Data. We may add this to the information we
            have already collected from you via our Site in order to improve the
            Services we provide. We do not collect Personal Data automatically,
            but we may tie the information that we collect automatically to
            Personal Data about you that we collect from other sources or that
            you provide to us.
          </p>
        </li>
        <li className={styles.column}>
          <p>10</p>
          <p>
            Creation of Anonymous Data. We may create Anonymous Data records
            from Personal Data by excluding information (such as your name) that
            makes the data personally identifiable to you. We use this Anonymous
            Data to analyze request and usage patterns so that we may enhance
            the content of our Services and improve Site navigation. We reserve
            the right to use Anonymous Data for any purpose and to disclose
            Anonymous Data to third parties without restriction.
          </p>
        </li>
        <li className={styles.column}>
          <p>11</p>
          <p>
            Disclosure of Your Personal Data. We disclose your Personal Data as
            described below and as described elsewhere in this Privacy Policy.
          </p>
        </li>
        <li className={styles.column}>
          <p>12</p>
          <p>
            Third Party Service Providers. We may share your Personal Data with
            third party service providers to: provide you with the Services that
            we offer you through our Site; to conduct quality assurance testing;
            to facilitate creation of accounts; to provide technical support;
            and/or to provide other services to the Company.
          </p>
        </li>
        <li className={styles.column}>
          <p>13</p>
          <p>
            Affiliates. We may share some or all of your Personal Data with our
            parent company, subsidiaries, joint ventures, or other companies
            under a common control ("Affiliates"), in which case we will require
            our Affiliates to honor this Privacy Policy.
          </p>
        </li>
        <li className={styles.column}>
          <p>14</p>
          <p>
            Corporate Restructuring. We may share some or all of your Personal
            Data in connection with or during negotiation of any merger,
            financing, acquisition or dissolution transaction or proceeding
            involving sale, transfer, divestiture, or disclosure of all or a
            portion of our business or assets. In the event of an insolvency,
            bankruptcy, or receivership, Personal Data may also be transferred
            as a business asset. If another company acquires our company,
            business, or assets, that company will possess the Personal Data
            collected by us and will assume the rights and obligations regarding
            your Personal Data as described in this Privacy Policy.
          </p>
        </li>
        <li className={styles.column}>
          <p>15</p>
          <p>
            As Legally Required. Regardless of any choices you make regarding
            your Personal Data (as described below), Company may disclose
            Personal Data if it believes in good faith that such disclosure is
            necessary (a) in connection with any legal investigation; (b) to
            comply with relevant laws or to respond to subpoenas or warrants
            served on Company; (c) to protect or defend the rights or property
            of Company or users of the Site or Services; and/or (d) to
            investigate or assist in preventing any violation or potential
            violation of the law, this Privacy Policy, or our Terms of Use.
          </p>
        </li>
        <li className={styles.column}>
          <p>16</p>
          <p>
            Other Disclosures. We may also disclose your Personal Data, to
            fulfill the purpose for which you provide it; for any other purpose
            disclosed by us when you provide it; or with your consent.
          </p>
        </li>
        <li className={styles.column}>
          <p>17</p>
          <p>
            How We Protect Your Information. We take reasonable technical and
            organizational measures to guard against unauthorized or unlawful
            processing of your personal data and against accidental loss or
            destruction of, or damage to, your personal data. While no system is
            completely secure, we believe the measures implemented by the Site
            reduce our vulnerability to security problems to a level appropriate
            to the type of data involved. We have security measures in place to
            protect our user database and access to this database is restricted
            internally. However, it remains your responsibility:
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>A)</p>
          <p className={styles.onlyText}>
            To protect against unauthorized access to your use of the Site
            and/or Services;
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>B)</p>
          <p className={styles.onlyText}>
            To ensure no one else uses the Site and/or Services while your
            machine is logged on to the Site (including by logging on to your
            machine through a mobile, Wi-Fi or shared access connection you are
            using);
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>C)</p>
          <p className={styles.onlyText}>
            To log off or exit from the Site and/or Services when not using it;
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>D)</p>
          <p className={styles.onlyText}>To maintain good internet security.</p>
        </li>
        <li className={styles.column}>
          <p>18</p>
          <p>
            Third Party Websites. Our Site may contain links to third party
            websites. When you click on a link to any other website or location,
            you will leave our Site and go to another site, and another entity
            may collect Personal Data or Anonymous Data from you. We have no
            control over, do not review, and cannot be responsible for, these
            outside websites or their content. Please be aware that the terms of
            this Privacy Policy do not apply to these outside websites or
            content, or to any collection of your Personal Data after you click
            on links to such outside websites. We encourage you to read the
            privacy policies of every website you visit. The links to third
            party websites or locations are for your convenience and do not
            signify our endorsement of such third parties or their products,
            content or websites.
          </p>
        </li>
        <li className={styles.column}>
          <p>19</p>
          <p>
            Your Choices Regarding Information. You have several choices
            regarding the use of information on our Services:
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>A)</p>
          <p className={styles.onlyText}>
            Changing or Deleting Your Personal Data. All users may review,
            update, correct or delete the Personal Data furnished by a user by
            contacting us. We will use commercially reasonable efforts to honor
            your request. We may retain an archived copy of Your records as
            required by law or for legitimate business purposes, such as
            information stored in blockchain technology for the purpose of
            verifying the property of the digital assets.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>B)</p>
          <p className={styles.onlyText}>
            We may use some of the information we collect for marketing
            purposes, including to send you promotional communications the
            Project features, products, events, or other opportunities. If you
            wish to stop receiving these communications or to opt out of use of
            your information for these purposes, please follow the opt-out
            instructions by clicking "Unsubscribe" (or similar opt-out language)
            in those communications.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>C)</p>
          <p className={styles.onlyText}>
            Email Communications. We could send you free newsletters and e-mails
            that directly promote the use of our Site or Services. When you
            receive newsletters or promotional communications from us, you may
            indicate a preference to stop receiving further communications from
            us and you will have the opportunity to "opt-out" by following the
            unsubscribe instructions provided in the e-mail you receive or by
            contacting us directly (please see contact information below).
            Despite your indicated e-mail preferences, we may send you
            service-related communications, including notices of any updates to
            our Terms and Conditions or Privacy Policy.
          </p>
        </li>
        <li className={styles.column}>
          <p>20</p>
          <p>
            Changes to This Privacy Policy. This Privacy Policy may be updated
            from time to time for any reason. We will notify you of any changes
            to our Privacy Policy by posting the new Privacy Policy. The date
            the Privacy Policy was last revised is identified at the beginning
            of this Privacy Policy. You are responsible for ensuring we have an
            up-to-date active and deliverable email address for you, and for
            periodically visiting our Site and this Privacy Policy to check for
            any changes.
          </p>
        </li>
        <li className={styles.column}>
          <p>21</p>
          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following;
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>A)</p>
          <p className={styles.onlyText}>
            The right to access - You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>B)</p>
          <p className={styles.onlyText}>
            The right to rectification - You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>C)</p>
          <p className={styles.onlyText}>
            The right to erasure - You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>D)</p>
          <p className={styles.onlyText}>
            The right to restrict processing - You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>E)</p>
          <p className={styles.onlyText}>
            The right to object to processing - You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
        </li>
        <li className={styles.column}>
          <p className={styles.onlyText}>F)</p>
          <p className={styles.onlyText}>
            The right to data portability - You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
        </li>
      </ul>

      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
