import { ReactElement } from "react";
import styles from "./Header.module.scss";
import headerHome from "./../../../assets/images/home/header-home-web.png";
import headerMobile from "./../../../assets/images/home/header-home-mobile.png";

const Header = (): ReactElement => (
  <>
    <img className={styles.headerHome} src={headerHome} alt="Raregotchi" />
    <img className={styles.headerMobile} src={headerMobile} alt="Raregotchi" />
  </>
);

export default Header;
