import { ReactElement, useEffect } from "react";

import styles from "./ModalImage.module.scss";

import cross from "../../../assets/images/my-collection/cross.png";
import Modal from "react-modal";
import useScrollLock from "../../../hooks/useScrollLock";

interface IProps {
  open: boolean;
  image: string;
  setOpenModal: (value: boolean) => void;
}

const ModalImage = ({ open, image, setOpenModal }: IProps): ReactElement => {
  const { lockScroll, unlockScroll } = useScrollLock();

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);

  return (
    <>
      {open && (
        <img
          src={cross}
          alt="close"
          onClick={handleClose}
          className={styles.cross}
        />
      )}
      <Modal
        isOpen={open}
        className={styles.modal}
        ariaHideApp={false}
        overlayClassName={styles.overlay}
        shouldCloseOnEsc={true}
        onRequestClose={handleClose}
      >
        <img src={image} className={styles.imageToy} alt="toy" />
      </Modal>
    </>
  );
};

export default ModalImage;
