import { ReactElement, useEffect } from "react";

import styles from "./SelectName.module.scss";
import cancelButton from "../../../../assets/images/my-collection/cancel.svg";
import continueButton from "../../../../assets/images/my-collection/continue.svg";
import useBackendApi from "../../../../hooks/useBackendApi";
import toysApi from "../../../../requests/toysApi";

interface IProps {
  handleClose: () => void;
  setStep: (value: number) => void;
  setFamilyName: (value: string) => void;
  setValidation: (validation: string) => void;
  step: number;
  familyName: string;
  validation: string;
}

interface IToysProps {
  color: string;
  image: string;
  owner: string;
  size: string;
  status: string;
  type: string;
  _id: string;
  createdAt: number;
  id: number;
  updatedAt: number;
}

const SelectName = ({
  setStep,
  handleClose,
  setValidation,
  setFamilyName,
  step,
  familyName,
  validation,
}: IProps): ReactElement => {
  const getToysApi = useBackendApi(toysApi.getToysByFamilyName);

  useEffect(() => {
    getToysApi.request(familyName);
  }, [familyName]);

  const toys = getToysApi.data as unknown as IToysProps[];

  const handleContinue = () => {
    if (toys?.length === 0) {
      setValidation("");
      setStep(step + 1);
    } else {
      setValidation("Name already in use.");
    }
  };

  return (
    <div className={styles.content}>
      <p className={styles.text}>Select your Family Name: </p>
      <input
        type="text"
        value={familyName}
        onChange={(e) => setFamilyName(e?.target?.value)}
      />
      <div className={styles.contentValidation}>
        <p className={styles.validation}>{validation}</p>
      </div>
      <p className={styles.subtext}>
        Your Family Name should be unique. <br />
        We reserve the right to remove offensive Family Names.
      </p>
      <div className={styles.contentButton}>
        <img
          src={cancelButton}
          role="button"
          alt="Cancel"
          onClick={handleClose}
        />
        <img
          src={continueButton}
          role="button"
          alt="Continue"
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default SelectName;
