import { ReactElement, useState } from "react";
import styles from "./Collapsible.module.scss";
import Plus from "../../../assets/images/faq/plus.png";
import Minus from "../../../assets/images/faq/minus.png";
import PlusResponsive from "../../../assets/images/faq/plus-responsive.png";
import MinusResponsive from "../../../assets/images/faq/minus-responsive.png";

interface IProps {
  question: string;
  children: ReactElement | ReactElement[];
}

const Collapsible = ({ question, children }: IProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={
        isOpen
          ? styles.collapsibleContainerExtended
          : styles.collapsibleContainer
      }
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={styles.questions}>{question}</div>
      <button className={styles.toggleAnswers}>
        {isOpen ? (
          <img src={Minus} alt="collapse" />
        ) : (
          <img src={Plus} alt="expand" />
        )}
      </button>
      <button className={styles.toggleAnswersResponsive}>
        {isOpen ? (
          <img src={MinusResponsive} alt="collapse" />
        ) : (
          <img src={PlusResponsive} alt="expand"></img>
        )}
      </button>
      {isOpen && (
        <div className={styles.answers}>
          <p>{children}</p>
        </div>
      )}
    </div>
  );
};

export default Collapsible;
