import { ReactElement } from "react";

import styles from "./Item.module.scss";
import { Link } from "react-router-dom";
import {
  MY_COLLECTION_PETS_PRODUCT,
  MY_COLLECTION_PRODUCT,
} from "../../../constants";
import { ToyStatus } from "../../../types";

interface IProps {
  id: string;
  name: string;
  image: string;
  status: string;
  specialID: string;
}

const Item = ({ id, name, image, status, specialID }: IProps): ReactElement => (
  <Link
    to={
      status === ToyStatus.PET
        ? MY_COLLECTION_PETS_PRODUCT.replace(":id", id)
        : MY_COLLECTION_PRODUCT.replace(":id", id)
    }
  >
    <div
      className={
        status.toLocaleLowerCase() === ToyStatus.SEALED
          ? styles.packagesContainer
          : status.toLocaleLowerCase() === ToyStatus.OPEN
          ? styles.packagesContainerOpen
          : styles.packagesContainerPet
      }
    >
      <img className={styles.toyImage} src={image} alt="Toy" />
      <p className={styles.toyName}>{name ? name : specialID}</p>
      <p className={styles.toyId}>#{id}</p>
      <p className={styles.viewButton}>VIEW</p>
    </div>
  </Link>
);

export default Item;
