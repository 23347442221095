import { ReactElement } from "react";
import styles from "./PrintYourPets.module.scss";
import Pets from "./Pets";

const PrintYourPets = (): ReactElement => (
  <div className={styles.printYourPets}>
    <div>
      <div className={styles.content}>
        <p>
          PETS are made of different parts, with Rare, Ultra-Rare, Epic and
          Legendary tiers.
        </p>

        <p>
          Origin PETS are assured to have at least one Ultra-Rare part. These
          are non-hereditary and will be available only in PETS printed from
          founder TOYS, making them unique.
        </p>
      </div>
      <div className={styles.pets}>
        <Pets />
      </div>
    </div>
  </div>
);

export default PrintYourPets;
