import { useState, useEffect, createContext, ReactNode } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { INFURA_ID } from "../constants/env";
import { ChainId } from "../constants/blockchain";

declare global {
  interface Window {
    ethereum: any;
  }
}

export interface IWeb3ContextProps {
  isInitialized: boolean;
  web3: Web3 | null;
  walletAddress: string;
  chainId: ChainId;
  signMessage: (message: string) => Promise<string>;
  connectWallet(): Promise<void>;
  disconnectWallet(): Promise<void>;
}

interface IWeb3ContextProvider {
  children: ReactNode;
}

export const Web3Context = createContext<IWeb3ContextProps | null>(null);

const Web3ContextProvider = ({
  children,
}: IWeb3ContextProvider): JSX.Element => {
  const [chainId, setChainId] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [provider, setProvider] = useState<any>(null);

  useEffect(() => {
    setupWeb3();
  }, []);

  useEffect(() => {
    if (provider === null) {
      return;
    }

    provider.on("accountsChanged", handleWalletChange);

    provider.on("chainChanged", (chainId: string) => {
      setChainId(parseInt(chainId, 16));
    });

    provider.on("disconnect", () => {
      setWalletAddress("");
    });
  }, [provider]);

  const setupWeb3 = async () => {
    const provider =
      window.ethereum ??
      new WalletConnectProvider({
        infuraId: INFURA_ID,
      });

    setProvider(provider);
    const web3 = new Web3(provider);

    setWeb3(web3);

    if (
      web3.currentProvider &&
      (web3 as any)._provider.wc &&
      (web3 as any)._provider.wc._accounts.length !== 0 // Checks if wallet connect has permission to connect without popping up modal
    ) {
      await provider.enable();
    }

    const accounts = await web3.eth.getAccounts();

    if (accounts.length > 0) {
      setWalletAddress(accounts[0]);
    }

    setChainId(await web3.eth.getChainId());
    setIsInitialized(true);
  };

  const connectWallet = async () => {
    if (!web3) {
      throw Error("Web3 is not initialized");
    }

    if (provider === null) {
      throw Error("No provider available");
    }

    web3.currentProvider && (web3 as any)._provider.wc
      ? await provider.enable()
      : await provider.request({ method: "eth_requestAccounts" });

    const accounts = await web3.eth.getAccounts();
    setWalletAddress(accounts[0]);
  };

  const handleWalletChange = (accounts: string[]) => {
    accounts.length === 0
      ? setWalletAddress("")
      : setWalletAddress(accounts[0]);
  };

  const signMessage = async (message: string) => {
    if (!web3) {
      throw Error("Web3 is not initialized");
    }

    const newSignature = await web3.eth.personal.sign(
      message,
      walletAddress,
      ""
    );

    return newSignature ?? "";
  };

  const disconnectWallet = async () => {};

  return (
    <Web3Context.Provider
      value={{
        isInitialized,
        web3,
        walletAddress,
        chainId,
        signMessage,
        connectWallet,
        disconnectWallet,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export default Web3ContextProvider;
