import { createContext, useState, useContext, ReactElement } from "react";
import { IStateContractProps } from "../types";

interface IContractContextProps {
  toysMintedTotal: number;
  setToysMintedTotal: (total: number) => void;
  toysMintedByWallet: number;
  setToysMintedByWallet: (minted: number) => void;
  toysTotalToken: number;
  setToysTotalToken: (total: number) => void;
  stateContract: IStateContractProps;
  setStateContract: (stateContract: IStateContractProps) => void;
  stateOpenToyContract: IStateContractProps;
  setStateOpenToyContract: (stateContract: IStateContractProps) => void;
  isToyOpen: boolean;
  setIsToyOpen: (open: boolean) => void;
}

interface IContractContextProvider {
  children: ReactElement;
}

export const ContractContext = createContext<IContractContextProps | undefined>(
  undefined
);

const ContractContextProvider = ({
  children,
}: IContractContextProvider): ReactElement => {
  const [stateContract, setStateContract] = useState<IStateContractProps>({
    type: "CALLING_CONTRACT",
    payload: {
      status: "WAITING_CONFIRMATION",
    },
  });

  const [stateOpenToyContract, setStateOpenToyContract] =
    useState<IStateContractProps>({
      type: "CALLING_CONTRACT",
      payload: {
        status: "WAITING_CONFIRMATION",
      },
    });

  const [toysMintedByWallet, setToysMintedByWallet] = useState(0);
  const [toysMintedTotal, setToysMintedTotal] = useState(0);
  const [toysTotalToken, setToysTotalToken] = useState(0);
  const [isToyOpen, setIsToyOpen] = useState(false);

  return (
    <ContractContext.Provider
      value={{
        stateContract,
        toysMintedByWallet,
        toysMintedTotal,
        toysTotalToken,
        stateOpenToyContract,
        isToyOpen,
        setIsToyOpen,
        setStateOpenToyContract,
        setToysMintedTotal,
        setToysMintedByWallet,
        setStateContract,
        setToysTotalToken,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

const useContractContext = (): IContractContextProps => {
  const context = useContext(ContractContext);

  if (context === undefined) {
    throw new Error(
      "useContractContext should be used within a ContractContextProvider"
    );
  }

  return context;
};

export { ContractContextProvider, useContractContext };
