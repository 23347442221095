import { useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import { FIRST_DROP_CONTRACT_ADDR } from "../../constants/env";
import FirstDropContractAbi from "../../contracts/FirstDropAbi";
import { useContractContext } from "../../context/contractContext";
import {
  EActionTypes,
  ETransactionStatus,
  EContractTransactionStatus,
} from "../../constants/enums";

import { IStateContractProps, ISignatureProps } from "../../types";

type TUseContract = {
  mint: (quantity: number) => void;
  mintedPerWalletByStage: (walletAddress: string) => void;
};

let firstDropContract: Web3["givenProvider"];

const useContract = (
  web3: Web3 | null,
  walletAddress: string,
  signature: ISignatureProps
): TUseContract => {
  const { setStateContract, setToysMintedByWallet } = useContractContext();

  useEffect(() => {
    if (web3) {
      firstDropContract = new web3.eth.Contract(
        FirstDropContractAbi as AbiItem[],
        FIRST_DROP_CONTRACT_ADDR
      );
    }
  }, [web3]);

  const handleState = (status: IStateContractProps) => {
    setStateContract(status);
  };

  const handleToysMintedByWallet = (toysMintedByWallet: number) => {
    setToysMintedByWallet(toysMintedByWallet);
  };

  const mint = async (quantity: number) => {
    const mintPrice = await firstDropContract.methods.mintPrice().call();

    await firstDropContract.methods
      .mint(
        quantity,
        signature.stage,
        signature.maxAmount,
        signature.fromTimestamp,
        signature.signature
      )
      .send({ from: walletAddress, value: mintPrice * quantity })
      .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) => {
        const status = {
          type: EActionTypes.TRANSACTION_HASH,
          payload: {
            txId: txId as string,
            status: ETransactionStatus.IN_PROGRESS,
          },
        };

        handleState(status);
      })
      .once(EContractTransactionStatus.ERROR, ({ message }: Error) => {
        const error = {
          type: EActionTypes.ERROR,
          payload: {
            error: { message: message as string },
            status: ETransactionStatus.ERROR,
          },
        };

        handleState(error);
      });
  };

  const mintedPerWalletByStage = async (walletAddress: string) => {
    const minted = await firstDropContract.methods
      .mintedPerWalletByStage(signature.stage, walletAddress)
      .call();

    handleToysMintedByWallet(parseInt(minted));
  };

  return {
    mint,
    mintedPerWalletByStage,
  };
};

export default useContract;
