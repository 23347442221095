import styles from "./Faq.module.scss";
import FaqLogo from "../../assets/images/faq/faq-logo.svg";
import FaqResponsive from "../../assets/images/faq/faq-responsive.svg";
import Collapsible from "./Collapsible";
import Header from "../Header";

const Faq = () => {
  return (
    <div className={styles.faqContainer}>
      <Header />

      <div className={styles.questionsContainer}>
        <img src={FaqLogo} alt="Faq" className={styles.faqLogo} />
        <img
          src={FaqResponsive}
          alt="Faq"
          className={styles.faqLogoResponsive}
        />
        <div className={styles.questionsList}>
          <Collapsible question="What is Raregotchi?">
            <p>
              Raregotchi is an NFT game inspired by the classic 90s virtual
              pets. It includes NFT collectible figures, breedable pets, and
              different games, all mixed in a crazy animated, cartoonish world!
            </p>
            <p>
              In Raregotchi, players can collect TOYS and PETS, and engage in an
              expanding universe of NFT games and experiences, including PVE &
              PVP competitions, collecting rare assets, participating in the
              RareDAO, earning rewards, and getting involved in lots of fun
              activities in a welcoming community
            </p>
          </Collapsible>

          <Collapsible question="What is a TOY?">
            <p>
              Raregotchi TOYS are collectible NFT figures that come sealed in a
              digital package. As a TOY owner, you can choose to open the
              package and unveil the mystery PETS inside the TOY.
            </p>
            <p>
              The first Raregotchi generation brings three Founder TOYS:{" "}
              <strong>School Kid</strong>, <strong>Snow Cone</strong>, and{" "}
              <strong>Alien Baby</strong>. There are 3000 NFT TOYS available,
              1000 units of each type.
            </p>
            <p>
              Founder TOYS come in three sizes: Regular, Large, and Huge. The
              size determines the number of OG PETS the TOY can mint: 3, 4, or
              6, respectively.
            </p>
            <p>
              Opening a Sealed TOY is an irreversible action. It cannot be
              undone!
            </p>
          </Collapsible>

          <Collapsible question="How much does a TOY cost?">
            <p>
              Each TOY costs 0.25 ETH. NFT minting is random, so you can't
              choose what TOY you get. There are two minting passes. Each one
              allows users to mint a different amount of NFT TOYS.
            </p>
          </Collapsible>

          <Collapsible question="How can I get a Raregotchi TOY?">
            <p>
              You’ll be able to acquire a TOY in the upcoming OG TOY Sale,
              starting on <strong>June 30th</strong>. To join the Sale, you’ll
              have to claim a special role in our official Discord server and
              subscribe your wallet to the Allowlist. We will provide more
              details closer to the sale date announcement.
            </p>
            <p>
              You’ll also be able to buy a Raregotchi TOY in our secondary
              market at OpenSea & LooksRare after the sale has ended.
            </p>
          </Collapsible>

          <Collapsible question="How can I get a physical TOY?">
            <p>
              If you own a Raregotchi TOY, you can claim a physical Vinyl
              version of your TOY. You will have to pay a small shipping fee to
              have the physical TOY sent to you.
            </p>
            <p>
              You can only claim one vinyl figure per NFT TOY. If you buy a TOY
              on the secondary market and the previous owner has not claimed the
              vinyl figure, you can claim it for yourself.
            </p>
          </Collapsible>

          <Collapsible question="When can I claim my Physical TOYS?">
            <p>
              Starting in Q4 2022, you’ll be able to claim a physical version of
              your TOY as long as you hold the digital NFT TOY in your wallet.
            </p>
          </Collapsible>
          <Collapsible question="Are you planning to launch more TOYS in the future?">
            <p>
              For the moment, we are not considering releasing more NFT Toys.
              Nevertheless, if we do so, it would be with the consent of the
              future RareDAO and the community of NFT holders.
            </p>
          </Collapsible>

          <Collapsible question="What is a PET?">
            <p>
              Raregotchi PETS are the citizens of the Rareverse! They are wacky
              creatures made of candy, human artifacts, and space goo.
            </p>
            <p>
              The OG PETS are the first generation of Raregotchi NFT creatures.
              They are the direct descendants of the Founder TOYS and have
              inherited the aspect and attributes of the three ancestral races:{" "}
              <strong>Snow Cone</strong>, <strong>School Kid</strong>, and{" "}
              <strong>Alien Baby</strong>.
            </p>
            <p>
              PETS come in five different rarities, each with unique appearances
              and attributes.
            </p>
          </Collapsible>
          <Collapsible question="How can I get a PET?">
            <p>
              To mint your PETS, you’ll need to open a{" "}
              <strong>Sealed TOY</strong>. You’ll also be able to acquire PETS
              in Raregotchi’s secondary market at OpenSea & LooksRare.
            </p>
          </Collapsible>
          <Collapsible question="What are PETS made of?">
            <p>PETS are made of seven different traits:</p>
            <ol>
              <li>Type</li>
              <li>
                <strong>Head</strong>
              </li>
              <li>
                <strong>Item</strong>
              </li>
              <li>
                <strong>Eyes</strong>
              </li>
              <li>
                <strong>Mouth</strong>
              </li>
              <li>Body Color</li>
              <li>Background Color</li>
            </ol>
            <p>
              Of those seven, the{" "}
              <span className={styles.italicFont}>Head</span>,{" "}
              <span className={styles.italicFont}>Item</span>,
              <span className={styles.italicFont}> Eyes</span>, and{" "}
              <span className={styles.italicFont}>Mouth</span> traits are also
              <strong> Body Parts</strong>.
            </p>
          </Collapsible>
          <Collapsible question="What type of PETS are there?">
            <p>
              OG PETS come in five rarity levels: Rare, Ultra-Rare, Epic, Shiny,
              and Legendary.
            </p>
            <p>
              Rare and Ultra Rare PETS are composed of special Body Parts sets,
              while the Epic, Shiny, and Legendary tier PETS feature a unique
              appearance based on a particular theme, making them exclusive
              collectible assets.
            </p>
          </Collapsible>
          <Collapsible question="How can I mint my PETS?">
            <p>
              If you own a Raregotchi TOY, you will be able to mint your PETS
              soon after the TOYS get revealed. Depending on the size of your
              TOY, you will be able to mint 3, 4, or 6 OG Pets.
            </p>
          </Collapsible>
          <Collapsible question="What’s the difference between OG PETS and PETS?">
            <p>
              Both terms refer to the same Raregotchi creatures. However, OG
              PETS are the NFT collectibles obtained directly from opening a
              Sealed TOY. Bred PETS will be the ones obtained via Breeding.
            </p>
            <p>
              OG PETS will have at least one Ultra-Rare Body Part (trait). The
              PETS created through Breeding will have all Rare body parts.
            </p>
          </Collapsible>
          <Collapsible question="What are Full-Set, Shuffle-Set, and MIX PETS?">
            <p>
              Rare and Ultra Rare PETS are made of Body Parts sets. A PET
              featuring all four Body Parts (
              <span className={styles.italicFont}>Head</span>,
              <span className={styles.italicFont}> Item</span>,
              <span className={styles.italicFont}> Eyes</span>,
              <span className={styles.italicFont}> Mouth</span> ) from the same
              set is considered a <strong>Full-Set PET</strong>.
            </p>
            <p>
              On the contrary, a PET featuring a combination of parts from the
              same rarity tier but a different set is considered a
              <strong> Shuffle-Set PET</strong>.
            </p>
            <p>
              MIX PETS are the ones that feature a combination of Ultra-Rare and
              Rare Body Parts. The more Ultra-Rare Body Parts a MIX PET has, the
              rarer it is.
            </p>
          </Collapsible>
          <Collapsible question="What is Breeding?">
            <p>
              As a Raregotchi collector, you can mate two of your PETS and breed
              a new PET. Breeding costs Rarepeaches, Raregotchi’s in-game token,
              and other in-game resources. You can earn them by playing the
              different Raregotchi games, or you can buy other users’ surplus
              tokens.
            </p>
            <p>
              Breeding allows you to create and discover new combinations of
              Raregotchi PETS. There will be up to 10.000.000+ possible
              variations.
            </p>
            <p>
              All PETS created through Breeding will belong to the Rare tier.
            </p>
          </Collapsible>
          <Collapsible question="When will Breeding be implemented?">
            <p>
              Breeding mechanics are expected to be implemented in Q2 2023.
              Check out our Roadmap for more information.
            </p>
          </Collapsible>
          <Collapsible question="What are Rarepeaches?">
            <p>
              Rarepeaches are Raregotchi’s in-game token. Users can farm
              Rarepeaches by playing our games. This token will be used to breed
              new pets, and new uses will be added as the ecosystem grows.
            </p>
          </Collapsible>
          <Collapsible question="On what type of games is Raregotchi working on?">
            <p>
              Raregotchi will bring an ecosystem of online games and
              experiences, allowing users to play with their Raregotchi
              collectible assets while earning tokens and other NFT rewards.
            </p>
            <p>
              We are currently working on two different game installments and an
              exclusive land-like utility asset, the Raregotchi Houses.
            </p>
            <p>
              <strong>Raregotchi, The Game:</strong> Raregotchi will be the
              entrance to the P4F ecosystem of the Rareverse. It is a
              resource-gathering game with aspects of the pet-raising genre.
              Raregotchi will be released as an MVP, and we will upgrade it with
              regular iterations throughout our Roadmap. The release of the
              alpha is expected for Q1 2023
            </p>
            <p>
              <strong>Raregotchi PVP game:</strong> The PVP mode will feature
              online battles and strategy aspects using PETS and different NFT
              artifacts. As a player, you can expect to:
            </p>
            <ul>
              <li>
                Engage in PVP battles using your PETS for glory and rewards!
              </li>
              <li>Join competitive tournaments and climb up in the ranks</li>
              <li>
                Choose a custom team of PETS and define the best tactics for the
                battle arena
              </li>
              <li>And much more!</li>
            </ul>
            <p>The PVP game mode is expected to be released in late 2023.</p>
          </Collapsible>
          <Collapsible question="What are Raregotchi Houses?">
            <p>
              Houses are a special type of Raregotchi asset. They are the space
              where the first Raregotchi game will take place, allowing users to
              interact with their PETS, explore the different elements of the
              House, and perform specific actions to farm tokens.
            </p>
            <p>
              TOY owners will have access to a free mint of a Raregotchi House.
              Houses come in various types and rarity tiers based on the three
              ancestral races: School Kid, Alien Baby, and Snow Cone.{" "}
            </p>
            <p>
              Each type of house will bring different benefits for its holders.
              The basic token-farming actions of the house won’t require using
              PETS. However, owning a PET will allow players to unlock the full
              potential of their Raregotchi House.
            </p>
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default Faq;
