import { ReactElement, useEffect, useState } from "react";

import pet1 from "./../../assets/images/pets/1.png";
import pet2 from "./../../assets/images/pets/2.png";
import pet3 from "./../../assets/images/pets/3.png";
import pet4 from "./../../assets/images/pets/4.png";
import pet5 from "./../../assets/images/pets/5.png";
import pet6 from "./../../assets/images/pets/6.png";
import pet7 from "./../../assets/images/pets/7.png";
import pet8 from "./../../assets/images/pets/8.png";
import pet9 from "./../../assets/images/pets/9.png";
import pet10 from "./../../assets/images/pets/10.png";
import pet11 from "./../../assets/images/pets/11.png";
import pet12 from "./../../assets/images/pets/12.png";
import pet13 from "./../../assets/images/pets/13.png";
import pet14 from "./../../assets/images/pets/14.png";
import pet15 from "./../../assets/images/pets/15.png";
import pet16 from "./../../assets/images/pets/16.png";
import pet17 from "./../../assets/images/pets/17.png";
import pet18 from "./../../assets/images/pets/18.png";
import pet19 from "./../../assets/images/pets/19.png";
import pet20 from "./../../assets/images/pets/20.png";
import pet21 from "./../../assets/images/pets/21.png";
import pet22 from "./../../assets/images/pets/22.png";
import pet23 from "./../../assets/images/pets/23.png";
import pet24 from "./../../assets/images/pets/24.png";

const petImages = [
  pet1,
  pet2,
  pet3,
  pet4,
  pet5,
  pet6,
  pet7,
  pet8,
  pet9,
  pet10,
  pet11,
  pet12,
  pet13,
  pet14,
  pet15,
  pet16,
  pet17,
  pet18,
  pet19,
  pet20,
  pet21,
  pet22,
  pet23,
  pet24,
];

const Pets = (): ReactElement => {
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((currentImage) =>
        currentImage < petImages.length - 1 ? currentImage + 1 : 0
      );
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  return <img src={petImages[currentImage]} alt="pet" />;
};

export default Pets;
