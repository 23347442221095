import { useState, useEffect } from "react";
import { getUnixTime } from "date-fns";

const useCurrentDate = () => {
  const [currentDate, setCurrentDate] = useState(0);

  useEffect(() => {
    setInterval(() => {
      const date = Date.now();
      setCurrentDate(getUnixTime(date));
    }, 1000);
  }, []);

  return {
    currentDate,
  };
};

export default useCurrentDate;
