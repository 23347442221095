import { ReactElement } from "react";
import styles from "./MeetTheToys.module.scss";
import meetTheToys from "./../../../assets/images/home/meet-the-toys.png";
import meetTheToysMobile from "./../../../assets/images/home/meet-the-toys-mobile.png";

import ButtonLarge from "../../Buttons/ButtonLarge";

const MeetTheToys = (): ReactElement => (
  <>
    <img
      className={styles.imageMeetTheToys}
      src={meetTheToys}
      alt="Meet the Toys"
    />
    <img
      className={styles.imageMeetTheToysMobile}
      src={meetTheToysMobile}
      alt="Meet The Toys"
    />
    <div className={styles.contentText}>
      <p className={styles.text}>
        Raregotchi is an NFT project inspired by the classic 90s virtual pets.
        Featuring collectible figures, breedable pets, and multiple game modes
        all mixed to create an amazingly fun, zany, and cartoonish world!
      </p>
      <p className={styles.text}>
        Raregochi TOYS are very special collectible NFT figures that come sealed
        in a digital package. Inside, they can contain 3, 4, or 6 OG PETS. The
        Rareverse begins with three Founder TOYS: School Kid, Snow Cone, and
        Alien Baby. There are only 3000 of them, 1000 of each type.
      </p>
      <p className={styles.text}>
        Opening a TOY allows you to mint and reveal the PETS and create your
        Raregotchi Lineage. Be warned, though! Opening a toy package is a
        permanent action and can’t be reversed!
      </p>
    </div>
    <div className={styles.contentSubscribe}>
      <a
        href="https://discord.gg/raregotchi"
        data-testid="join-our-community-first"
        target="_blank"
        rel="noreferrer noopener"
      >
        <ButtonLarge text="JOIN OUR COMMUNITY" alt="join our community" />
      </a>
    </div>
  </>
);

export default MeetTheToys;
