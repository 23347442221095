import { ReactElement } from "react";

import styles from "./Error.module.scss";

const Error = (): ReactElement => (
  <div className={styles.content} data-testid="content-error">
    <p>
      Oops! Something went wrong. <br /> Please try again.
    </p>
  </div>
);

export default Error;
