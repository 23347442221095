import apiBackendClient from "../utils/apiBackendClient";

import { TOYS, SIGNATURES_OPEN } from "../constants";

const getToy = (id: string) => apiBackendClient.get(`${TOYS}/${id}`);

const getToys = (owner: string) =>
  apiBackendClient.get(TOYS, { params: { owner: owner } });

const getToysByFamilyName = (familyName: string) =>
  apiBackendClient.get(TOYS, { params: { familyName } });

const openToy = (
  toyId: number,
  familyName: string,
  walletAddress: string,
  signedMessage: string
) => {
  // TO-DO: This headers is variables in all users
  const headers = {
    "auth-public-address": "0xf9dd25ff1278BE20EFfA9aC83027ace697F7AA55",
    "auth-signed-message":
      "0xd3c88cf16fddc2834cb1988baa9c69cd195a4d94d11c205e7f1770feae75734636f641fe0f28c681f00bae1d581aab47e71a0b2638a7089f9e002114a37102701b",
  };

  const body = {
    toyId: toyId,
    familyName: familyName,
  };

  try {
    const data = apiBackendClient.post(`${SIGNATURES_OPEN}`, body, {
      headers: headers,
    });

    return data;
  } catch (e) {
    throw e;
  }
};

const methods = {
  getToy,
  openToy,
  getToys,
  getToysByFamilyName,
};

export default methods;
