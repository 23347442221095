import { ReactElement } from "react";

import styles from "./MessageWarning.module.scss";
import cancelButton from "../../../../assets/images/my-collection/cancel.svg";
import continueButton from "../../../../assets/images/my-collection/continue.svg";

interface IProps {
  handleClose: () => void;
  setStep: (value: number) => void;
  step: number;
}

const MessageWarning = ({
  setStep,
  handleClose,
  step,
}: IProps): ReactElement => {
  return (
    <>
      <p className={styles.text}>
        <strong>WARNING: </strong> Opening a TOY is a permanent action and
        cannot be reversed!
      </p>
      <br />
      <p className={styles.text}>
        Opening a Toy allows you mint your OG PETS and create your own <br />
        Raregotchi lineage, by giving your PETS a unique Family Name
      </p>
      <br />
      <p className={styles.text}>
        The Family Name will be inherited by future generations of bred PETS
      </p>
      <div className={styles.contentButton}>
        <img
          role="button"
          src={cancelButton}
          alt="Cancel"
          onClick={handleClose}
        />
        <img
          role="button"
          src={continueButton}
          alt="Continue"
          onClick={() => setStep(step + 1)}
        />
      </div>
    </>
  );
};

export default MessageWarning;
