import { ReactElement } from "react";

import styles from "./PetCard.module.scss";

interface IProps {
  image: string;
  button: string;
  altButton: string;
  altPets: string;
  children: React.ReactNode;
}

const PetCard = ({
  image,
  button,
  altButton,
  altPets,
  children,
}: IProps): ReactElement => (
  <div className={styles.petCard}>
    <img src={image} className={styles.imagePets} alt={altPets} />
    <div className={styles.innerBox}>
      <div>
        <img src={button} alt={altButton} className={styles.imageButton} />
      </div>
      <div className={styles.contentText}>
        <p>{children}</p>
      </div>
    </div>
  </div>
);

export default PetCard;
