import { ReactElement } from "react";

import styles from "./Success.module.scss";

import myCollectionButton from "../../../../assets/images/my-collection/my-collection-button.png";
import { useContractContext } from "../../../../context/contractContext";
import { ETHERSCAN_URL, MY_COLLECTION } from "../../../../constants";
import useScrollLock from "../../../../hooks/useScrollLock";
import { Link } from "react-router-dom";
import loadingOpening from "./../../../../assets/images/toyOpening/loading-opening.gif";

const Response = (): ReactElement => {
  const { stateOpenToyContract } = useContractContext();
  const txId: string = stateOpenToyContract.payload.txId as string;
  const { unlockScroll } = useScrollLock();

  return (
    <div className={styles.content} data-testid="content-success">
      <img src={loadingOpening} alt="" />
      <p className={styles.text}>Transaction in Progress</p>
      <a
        href={`${ETHERSCAN_URL}/tx/${txId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {txId && <p className={styles.url}>{`${ETHERSCAN_URL}/tx/${txId}`}</p>}
      </a>

      <p className={styles.firstP}>
        It may take up to 10 blocks to confirm the transaction
      </p>
      <p className={styles.secondP}>
        PLEASE WAIT...
        <br /> THIS MAY TAKE A FEW MINUTES
      </p>
      <div className={styles.contentButton}>
        <Link to={`${MY_COLLECTION}?open-toy`}>
          <img
            src={myCollectionButton}
            alt="My collection"
            onClick={() => unlockScroll()}
          />
        </Link>
      </div>
    </div>
  );
};

export default Response;
