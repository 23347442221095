import { ReactElement } from "react";
import styles from "./MintYourPets.module.scss";
import PrintYourPets from "./../../PrintYourPets";
import titleMintYourPets from "./../../../assets/images/home/mint-your-pets.svg";
import PetCardsContainer from "../PetCardsContainer";
import PetCard from "../PetCard";

import trainButton from "./../../../assets/images/home/train.png";
import breedButton from "./../../../assets/images/home/breed.png";
import battleButton from "./../../../assets/images/home/battle.png";
import imageTrain from "./../../../assets/images/home/image-train.png";
import imageBreed from "./../../../assets/images/home/image-breed.png";
import imageBattle from "./../../../assets/images/home/image-battle.png";

import stain from "../../../assets/images/home/stain.png";

const MintYourPets = (): ReactElement => (
  <div className={styles.mintYourPets}>
    <img
      className={styles.title}
      src={titleMintYourPets}
      alt="Print Your Pets"
    />
    <PrintYourPets />
    <PetCardsContainer reverseBackground>
      <PetCard
        image={imageBattle}
        button={battleButton}
        altButton={"battle"}
        altPets={"battle pet"}
      >
        Compete against friends and foes in tactical PVP and PVE battles for
        rewards!
      </PetCard>

      <PetCard
        image={imageTrain}
        button={trainButton}
        altButton={"train"}
        altPets={"train pet"}
      >
        Embark in an adventure where your pets can progress and get stronger!
      </PetCard>

      <PetCard
        image={imageBreed}
        button={breedButton}
        altButton={"breed"}
        altPets={"breed pet"}
      >
        The more, the merrier! Breed pets to expand your lineage and get
        super-rare pet combinations.
      </PetCard>
      <img src={stain} className={styles.stain} alt="" />
    </PetCardsContainer>
  </div>
);
export default MintYourPets;
