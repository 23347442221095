import { useState } from "react";

const useBackendApi = (apiFunc: Function) => {
  const [data, setData] = useState();
  const [status, setStatus] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const request = async (...args: any) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result.data);
      setStatus(result.status);
    } catch ({ message }) {
      setError((message as string) || "Unexpected Error!");
      setData(undefined);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    status,
    error,
    loading,
    request,
  };
};

export default useBackendApi;
