import { useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import secondDropContractAbi from "../../contracts/SecondDropAbi";
import { TOY_CONTRACT_ADDRESS } from "../../constants/env";
import { useContractContext } from "../../context/contractContext";

type TUseContract = {
  toyOpen: () => void;
};

let secondDropContract: Web3["givenProvider"];

const useToyOpen = (web3: Web3 | null, id: number): TUseContract => {
  const { setIsToyOpen } = useContractContext();

  useEffect(() => {
    if (web3) {
      secondDropContract = new web3.eth.Contract(
        secondDropContractAbi as AbiItem[],
        TOY_CONTRACT_ADDRESS
      );
    }
  }, [web3]);

  const handleIsToyOpen = (open: boolean) => {
    setIsToyOpen(open);
  };

  const toyOpen = async () => {
    const open = await secondDropContract?.methods.toyOpen(id).call();

    handleIsToyOpen(open);
  };

  return {
    toyOpen,
  };
};

export default useToyOpen;
