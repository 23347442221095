import { ReactElement, useContext, useEffect, useState } from "react";

import styles from "./MyCollection.module.scss";
import Item from "./Item";

import myCollectionTitle from "../../assets/images/my-collection/my-collection.svg";
import useBackendApi from "../../hooks/useBackendApi";
import toysApi from "../../requests/toysApi";
import petsApi from "../../requests/petsApi";
import { IWeb3ContextProps, Web3Context } from "../../context/web3Context";
import { IPetsProps, IToysProps, ToyStatus } from "../../types";
import Button from "../Buttons/Button";
import ButtonLarge from "../Buttons/ButtonLarge";
import Header from "../Header";
import ToyOpening from "./../ToyOpening";
import { useLocation } from "react-router-dom";

const MyCollection = (): ReactElement => {
  const { search } = useLocation();
  const [offset, setOffset] = useState(0);
  const [scroll, setScroll] = useState(false);
  const [pets, setPets] = useState([]);
  const getToysByOwnerApi = useBackendApi(toysApi.getToys);
  const getPetsByOwnerApi = useBackendApi(petsApi.getPetsForOwner);
  const { walletAddress } = useContext(Web3Context) as IWeb3ContextProps;
  const [isOpenToy, setIsOpenToy] = useState(false);
  const [showToys, setShowToys] = useState(true);
  const [showPets, setShowPets] = useState(true);

  const LIMIT = 8;
  const LIMIT_SCROLL_BOTTOM = 500;

  const handleClickToys = () => {
    if (!showPets) {
      return;
    }
    setShowToys(!showToys);
  };

  const handleClickPets = () => {
    if (!showToys) {
      return;
    }
    setShowPets(!showPets);
  };

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    getToysByOwnerApi.request(walletAddress);
  }, [walletAddress]);

  useEffect(() => {
    const openToy = new URLSearchParams(search).get("open-toy");
    if (openToy !== null) {
      setIsOpenToy(true);
    }
  }, [search]);

  useEffect(() => {
    if (offset !== 0) {
      return;
    }

    const array = [...(getPetsByOwnerApi.data ?? [])];
    setPets(array);
  }, [getPetsByOwnerApi.data]);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    getPetsByOwnerApi.request(walletAddress, LIMIT, offset);
  }, [walletAddress, offset]);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const array = [...(pets ?? []), ...(getPetsByOwnerApi.data ?? [])];
    setPets(array);
  }, [getPetsByOwnerApi.data]);

  window.addEventListener("scroll", () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (clientHeight + scrollTop >= scrollHeight - LIMIT_SCROLL_BOTTOM) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  });

  useEffect(() => {
    if (scroll) {
      setOffset(offset + LIMIT);
    }
  }, [scroll]);

  return (
    <div className={styles.myCollectionContainer}>
      <Header />
      {isOpenToy && <ToyOpening close={false} />}
      <img
        className={styles.myCollectionTitle}
        src={myCollectionTitle}
        alt="My Collection"
      />
      <div className={styles.filters}>
        <div
          className={showToys ? styles.filterNameActive : styles.filterName}
          onClick={handleClickToys}
        >
          <strong>TOYS</strong>
        </div>
        <div className={styles.filterSeparator}>
          <strong>|</strong>
        </div>
        <div
          className={showPets ? styles.filterNameActive : styles.filterName}
          onClick={handleClickPets}
        >
          <strong>PETS</strong>
        </div>
      </div>
      <div className={styles.toySectionContainer}>
        {getToysByOwnerApi.loading ? (
          <p className={styles.text}>Loading...</p>
        ) : (
          <>
            {getToysByOwnerApi.error && (
              <p className={styles.text}>{getToysByOwnerApi.error}</p>
            )}
            {(getToysByOwnerApi.data === undefined ||
              (getToysByOwnerApi.data as IToysProps[]).length === 0 ||
              !walletAddress) && (
              <>
                <p className={styles.collectionEmpty}>
                  Oops! It looks like your collection is empty. Go get your
                  collection started on OpenSea!
                </p>
                <div className={styles.contentButton}>
                  <a
                    href="https://opensea.io/collection/raregotchi"
                    data-testid="join-our-community-first"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button text={"GO TO OPENSEA"} alt="go to opensea" />
                  </a>
                  <a
                    href="https://discord.gg/raregotchi"
                    data-testid="join-our-community-first"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <ButtonLarge
                      text="JOIN OUR COMMUNITY"
                      alt="join our community"
                    />
                  </a>
                </div>
              </>
            )}
            {walletAddress && (
              <div className={styles.toySection}>
                {showToys &&
                  (getToysByOwnerApi.data ?? []).map(
                    ({ id, type, image, status }) => (
                      <Item
                        key={`TOY-${id}`}
                        id={id}
                        name={type}
                        image={image}
                        status={status}
                        specialID=""
                      />
                    )
                  )}
                {showPets &&
                  ((pets ?? []) as IPetsProps[]).map(
                    ({ id, type, image, properties }) => {
                      const specialNameArray = properties?.filter(
                        (el) => el.name === "X ID"
                      );
                      return (
                        <Item
                          key={`PET-${id}`}
                          id={id.toString()}
                          name={type}
                          image={image}
                          status={ToyStatus.PET}
                          specialID={specialNameArray[0]?.value}
                        />
                      );
                    }
                  )}
              </div>
            )}
            {getPetsByOwnerApi.loading && (
              <p className={styles.text}>Loading...</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyCollection;
