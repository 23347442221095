import { ReactElement } from "react";

import styles from "./ToysSoldOut.module.scss";
import ogToysSoldOutTitle from "../../../assets/images/home/og-toys-sold-out.svg";
import ButtonDisabled from "../../Buttons/ButtonDisabled";

const ToysSoldOut = (): ReactElement => {
  return (
    <>
      <img
        className={styles.eventTitle}
        src={ogToysSoldOutTitle}
        alt="Toys sold out"
      />
      <div className={styles.contentText}>
        <p>
          The OG TOY SALE just sold out! Thank you all for believing in this
          project and joining the Rareverse. The unveiling of the TOYS will be
          announced shortly on our Discord.
        </p>
        <p className={styles.text}>See you all there!</p>
        <br />
      </div>
      <ButtonDisabled text="SOLD OUT!" alt="button sold out" />
    </>
  );
};

export default ToysSoldOut;
