import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./Product.module.scss";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import header from "../../assets/images/my-collection/header-collection.png";
import headerMobile from "../../assets/images/my-collection/header-mobile.png";

import Error404 from "../Error404";
import collection from "../../assets/images/my-collection/collection.svg";
import titleCollection from "../../assets/images/my-collection/my-collection.svg";
import useBackendApi from "../../hooks/useBackendApi";
import toysApi from "../../requests/toysApi";
import BackButton from "../BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMintStagesOpenProps, IToysProps, ToyStatus } from "../../types";
import Modal from "./Modal";
import ModalImage from "./ModalImage";
import Button from "../Buttons/Button";
import { IWeb3ContextProps, Web3Context } from "../../context/web3Context";
import { TOY_CONTRACT_ADDRESS } from "../../constants";
import {
  ContractContextProvider,
  useContractContext,
} from "../../context/contractContext";
import Modal3d from "./Modal3D";
import useToyOpen from "../../hooks/contracts/useToyOpen";
import mintStagesOpenApi from "../../requests/mintStagesOpenApi";
import useCurrentDate from "../../hooks/useCurrentDate";
import Header from "../Header";

const Product = (): ReactElement => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal3D, setOpenModal3D] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);
  const { walletAddress, web3 } = useContext(Web3Context) as IWeb3ContextProps;
  const getMintStagesOpenApi = useBackendApi(
    mintStagesOpenApi.getMintStagesOpen
  );
  const { currentDate } = useCurrentDate();

  let { id } = useParams<{ id: string }>();

  const getToyApi = useBackendApi(toysApi.getToy);

  useEffect(() => {
    getToyApi.request(id);
    getMintStagesOpenApi.request();
  }, []);

  const textTransformed = (text: string | null) => {
    if (!text) {
      return;
    }

    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  const toyData = getToyApi.data as unknown as IToysProps;
  const mintStagesOpenData =
    getMintStagesOpenApi.data as unknown as IMintStagesOpenProps;
  const status = toyData?.status.toLowerCase();

  const { isToyOpen } = useContractContext();
  const { toyOpen } = useToyOpen(web3, toyData?.id);

  useEffect(() => {
    if (!toyData?.id) {
      return;
    }
    toyOpen();
  }, [toyData?.id, isToyOpen]);

  const toggleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const toggleOpenModal3d = () => {
    setOpenModal3D(!openModal3D);
  };

  const toggleOpenModalImage = () => {
    setOpenModalImage(!openModalImage);
  };

  if (getToyApi.error) {
    return <Error404 />;
  }

  return (
    <>
      <div
        className={
          status === ToyStatus.SEALED
            ? styles.myCollection
            : styles.myCollectionOpen
        }
      >
        <Header />
        <div
          className={
            status === ToyStatus.VEILED && toyData?.owner !== walletAddress
              ? styles.contentCollectionVeiled
              : styles.contentCollection
          }
        >
          {getToyApi.loading && <p>Toy is loading!</p>}
          {getToyApi.error && <p>{getToyApi.error}</p>}
          <div className={styles.toyInfoContainer}>
            <div className={styles.contentDescription}>
              <div className={styles.backButtonContainer}>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={styles.arrowBack}
                />
                <BackButton />
              </div>
              {toyData?.owner !== walletAddress && (
                <img
                  className={styles.collection}
                  src={collection}
                  alt="collection"
                />
              )}
              {toyData?.owner === walletAddress && (
                <img
                  className={styles.myCollectionTitle}
                  src={titleCollection}
                  alt="title collection"
                />
              )}

              {status === ToyStatus.SEALED && (
                <div className={styles.sealedImageContainerMobile}>
                  <img
                    src={toyData?.image}
                    alt="toy"
                    className={styles.sealedImageMobile}
                    onClick={toggleOpenModalImage}
                    role="button"
                  />
                </div>
              )}
              {status === ToyStatus.OPEN && (
                <div className={styles.openImageContainerMobile}>
                  <img
                    src={toyData?.image}
                    alt="toy"
                    className={styles.openImageMobile}
                    onClick={toggleOpenModalImage}
                    role="button"
                  />
                </div>
              )}
              <p className={styles.title}>
                {textTransformed(toyData?.type)}
                {status !== ToyStatus.OPEN &&
                  status !== ToyStatus.VEILED &&
                  " - "}
                {status !== ToyStatus.OPEN && status}
                {status !== ToyStatus.VEILED && " - "}
                {status !== ToyStatus.VEILED && toyData?.size}
              </p>
              <p className={styles.tokenId}>
                #{toyData?.id}
                {status === ToyStatus.OPEN && " - "}
                {status === ToyStatus.OPEN && toyData?.familyName}
              </p>
              <div className={styles.productDescriptionContainer}>
                {status === ToyStatus.SEALED && (
                  <p className={styles.productDescription}>
                    This is a sealed TOY. A truly rare and pristine collectible.
                    Very valuable. But will it stay this way forever? Will you
                    resist the urge to open it?
                    <br />
                    <br />
                    Opening a sealed TOY allows you to mint your OG PETS and
                    create your Raregotchi Lineage by giving your PETS a unique
                    FAMILY name.
                  </p>
                )}
                {status === ToyStatus.OPEN && (
                  <p className={styles.productDescription}>
                    This is an Opened TOY, a very valuable collectible piece of
                    Raregotchi’s ecosystem. This TOY has already minted its OG
                    PETS and its Lineage is expanding across the Rareverse.
                    <br />
                    <br /> You should feel very fortunate. Owning this TOY
                    allows you to enjoy many perks and benefits.
                  </p>
                )}
              </div>
              <div className={styles.contentButton}>
                {status === ToyStatus.SEALED &&
                  toyData?.owner === walletAddress &&
                  !isToyOpen &&
                  mintStagesOpenData?.startAt <= currentDate && (
                    <Button
                      text="OPEN ME"
                      callback={toggleOpenModal}
                      alt="button the open"
                    />
                  )}
                {toyData?.owner === walletAddress && (
                  <a
                    href={`https://opensea.io/assets/ethereum/${TOY_CONTRACT_ADDRESS}/${toyData?.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button text="SELL ME" alt="button the sell" />
                  </a>
                )}
                {toyData?.owner !== walletAddress && (
                  <div className={styles.buttonOpensea}>
                    <a
                      href={`https://opensea.io/assets/ethereum/${TOY_CONTRACT_ADDRESS}/${toyData?.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button text="VIEW ON OPENSEA" alt="View on Opensea" />
                    </a>
                  </div>
                )}
                {status !== ToyStatus.VEILED && (
                  <div
                    className={styles.button3dLink}
                    onClick={toggleOpenModal3d}
                  >
                    <Button text="VIEW 3D" alt="View 3d" />
                  </div>
                )}
              </div>
            </div>
            {status === ToyStatus.SEALED && (
              <div className={styles.sealedImageContainer}>
                <img
                  className={styles.sealedImage}
                  src={toyData?.image}
                  onClick={toggleOpenModalImage}
                  role="button"
                  alt=""
                />
              </div>
            )}
            {status === ToyStatus.OPEN && (
              <div className={styles.openImageContainer}>
                <img
                  className={styles.openImage}
                  src={toyData?.image}
                  onClick={toggleOpenModalImage}
                  role="button"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal open={openModal} setOpenModal={setOpenModal} toyId={toyData?.id} />
      <ModalImage
        open={openModalImage}
        setOpenModal={setOpenModalImage}
        image={toyData?.image}
        status={status}
      />
      <Modal3d
        open={openModal3D}
        setOpenModal={setOpenModal3D}
        model3d={toyData?.model3d}
        type={toyData?.type ?? ""}
        size={toyData?.size ?? ""}
      />
    </>
  );
};

const ProductProvider = () => (
  <ContractContextProvider>
    <Product />
  </ContractContextProvider>
);

export default ProductProvider;
