import { ReactElement } from "react";
import styles from "./MeetThePets.module.scss";
import PetCard from "../PetCard";
import PetCardsContainer from "./../PetCardsContainer";

import imageMeetThePets from "./../../../assets/images/home/meet-the-pets.png";
import imageCollect from "./../../../assets/images/home/image-collect.png";
import imagePlay from "./../../../assets/images/home/image-play.png";
import imageExplore from "./../../../assets/images/home/image-explore.png";

import collectButton from "./../../../assets/images/comingSoon/collect.png";
import playButton from "./../../../assets/images/comingSoon/play.png";
import exploreButton from "./../../../assets/images/comingSoon/explore.png";

import stain from "../../../assets/images/home/stain.png";

const MeetThePets = (): ReactElement => (
  <div className={styles.meetThePets}>
    <div className={styles.titleContainer}>
      <img
        src={imageMeetThePets}
        className={styles.title}
        alt="Meet The Pets"
      />
    </div>
    <PetCardsContainer>
      <PetCard
        image={imageCollect}
        button={collectButton}
        altButton={"collect"}
        altPets={"pet collect"}
      >
        There are 9999 unique pets and 10.000.000+ possible breeding
        combinations!
      </PetCard>

      <PetCard
        image={imagePlay}
        button={playButton}
        altButton={"play"}
        altPets={"pet play"}
      >
        Battle with your pets in many adventures and challenge your friends to a
        duel!
      </PetCard>

      <PetCard
        image={imageExplore}
        button={exploreButton}
        altButton={"earn"}
        altPets={"pet earn"}
      >
        Discover the Rareverse to earn tokens, NFTs and more.
      </PetCard>
      <img src={stain} className={styles.stain} alt="" />
    </PetCardsContainer>

    <div className={styles.bottomText}>
      <p>
        Raregotchi pets, also collectible NFT figures, are the citizens of the
        Rareverse! They are wacky creatures made of cool oddities, sweets and
        slime. You can create your own Raregotchi lineage by opening a toy and
        giving your pets a custom surname.
      </p>
    </div>
  </div>
);

export default MeetThePets;
