import { ReactElement, FormEvent, useEffect } from "react";
import Modal from "react-modal";

import styles from "./Modal.module.scss";

import cross from "../../../../assets/images/my-collection/cross.png";
import { EActionTypes, ETransactionStatus } from "../../../../constants/enums";
import Minting from "./Minting";
import Success from "./Success";
import Error from "./Error";
import { IStateContractProps } from "../../../../types";
import useScrollLock from "../../../../hooks/useScrollLock";

interface IProps {
  redirectMyCollection: () => void;
  handleOnSubmit: (e: FormEvent) => void;
  setStateContract: (stateContract: IStateContractProps) => void;
  setOpenModal: (open: boolean) => void;
  open: boolean;
  amount: number;
  total: Number;
  stateContract: IStateContractProps;
}

const ModalMint = ({
  handleOnSubmit,
  redirectMyCollection,
  setStateContract,
  setOpenModal,
  open,
  amount,
  total,
  stateContract,
}: IProps): ReactElement => {
  const { lockScroll, unlockScroll } = useScrollLock();

  const handleClose = () => {
    setOpenModal(false);

    setStateContract({
      type: EActionTypes.CALLING_CONTRACT,
      payload: {
        status: ETransactionStatus.WAITING_CONFIRMATION,
      },
    });
  };

  const { status } = stateContract.payload;

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      className={styles.modal}
      ariaHideApp={false}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={handleClose}
    >
      <div className={styles.modalContent}>
        <div className={styles.contentCross}>
          <img src={cross} alt="close" onClick={handleClose} role="button" />
        </div>
        {status === ETransactionStatus.WAITING_CONFIRMATION && (
          <Minting
            setOpenModal={setOpenModal}
            handleOnSubmit={handleOnSubmit}
            amount={amount}
            total={total}
          />
        )}
        {status === ETransactionStatus.IN_PROGRESS && (
          <Success
            stateContract={stateContract}
            redirectMyCollection={redirectMyCollection}
          />
        )}
        {status === ETransactionStatus.ERROR && <Error />}
      </div>
    </Modal>
  );
};

export default ModalMint;
