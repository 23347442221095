import { useState, useEffect, Fragment, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ReactModal from "react-modal";

import styles from "./NavigationBar.module.scss";
import ResponsiveMenu from "../MobileMenu";
import useScrollLock from "../../hooks/useScrollLock";
import navLogo from "../../assets/images/navigation-bar/logo-min.png";
import navLogoResponsive from "../../assets/images/navigation-bar/logo-mobile-min.png";
import {
  FAQ,
  HOME,
  MINT,
  MY_COLLECTION,
  RAREPAPER_URL,
  ROADMAP,
  SCROLL,
} from "../../constants";
import { IWeb3ContextProps, Web3Context } from "../../context/web3Context";
import ButtonSmall from "../Buttons/ButtonSmall";
import useTotalToken from "../../hooks/contracts/useTotalToken";
import {
  ContractContextProvider,
  useContractContext,
} from "../../context/contractContext";

const NavigationBar = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { lockScroll } = useScrollLock();
  const location = useLocation();
  const [stickyClass, setStickyClass] = useState("");
  const PIXELS_FROM_TOP = 15;
  const { web3, walletAddress, connectWallet } = useContext(
    Web3Context
  ) as IWeb3ContextProps;
  const { toysTotalToken, toysMintedTotal } = useContractContext();

  const { totalSupply, totalTokens } = useTotalToken(web3);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1366px)" });

  const connectWithMetamaskOrWalletConnect = async () => {
    if (!walletAddress) {
      await connectWallet();
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > PIXELS_FROM_TOP
        ? setStickyClass(styles.stickyNav)
        : setStickyClass("");
    }
  };

  useEffect(() => {
    totalTokens();
    totalSupply();
  }, [toysTotalToken, toysMintedTotal]);

  useEffect(() => {
    window.addEventListener(SCROLL, stickNavbar);
    return () => window.removeEventListener(SCROLL, stickNavbar);
  }, []);

  return (
    <Fragment>
      <div
        className={`${styles.navbar} ${stickyClass}`}
        data-testid="navigation-bar"
      >
        <div className={styles.logoContainer}>
          <Link to={HOME} className={styles.navLogoResponsive}>
            <img
              className={styles.navigationLogoResponsive}
              src={navLogoResponsive}
              alt="navLogo"
            />
          </Link>
          <Link to={HOME} className={styles.navLogo}>
            <img
              className={styles.navigationLogo}
              src={navLogo}
              alt="navLogo"
            />
          </Link>
          <FontAwesomeIcon
            icon={faBars as IconProp}
            className={styles.menuIconMobile}
            onClick={() => {
              setShowMenu(true);
              lockScroll();
            }}
          />
          <FontAwesomeIcon
            icon={faBars as IconProp}
            className={styles.menuIconIpad}
            size="2x"
            onClick={() => {
              setShowMenu(true);
              lockScroll();
            }}
          />
        </div>

        <div className={styles.navOptions}>
          <ul>
            <li>
              <Link
                to={HOME}
                className={
                  location.pathname === HOME
                    ? styles.itemLinkActive
                    : styles.itemLink
                }
              >
                HOME
              </Link>
            </li>
            <li>
              <a
                className={styles.itemLink}
                href={RAREPAPER_URL}
                target="_blank"
                rel="noreferrer"
              >
                RAREPAPER
              </a>
            </li>
            <li>
              <Link
                to={ROADMAP}
                className={
                  location.pathname === ROADMAP
                    ? styles.itemLinkActive
                    : styles.itemLink
                }
              >
                ROADMAP
              </Link>
            </li>
            <li>
              <Link
                to={FAQ}
                className={
                  location.pathname === FAQ
                    ? styles.itemLinkActive
                    : styles.itemLink
                }
              >
                FAQ
              </Link>
            </li>
            {(toysTotalToken > toysMintedTotal ||
              !toysTotalToken ||
              !toysMintedTotal) && (
              <li>
                <Link
                  to={MINT}
                  className={
                    location.pathname === MINT
                      ? styles.itemLinkActive
                      : styles.itemLink
                  }
                >
                  MINT
                </Link>
              </li>
            )}
            <li>
              {!walletAddress ? (
                <div onClick={connectWithMetamaskOrWalletConnect}>
                  <ButtonSmall text="CONNECT" alt="Connect" />
                </div>
              ) : (
                <Link to={MY_COLLECTION}>
                  <ButtonSmall text="MY COLLECTION" alt="My collection" />
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
      {isTabletOrMobile && (
        <ReactModal
          isOpen={showMenu}
          style={{
            overlay: {
              zIndex: 1000,
              background: "rgba(58, 58, 58, 0.48)",
              backdropFilter: "blur(1rem)",
              WebkitBackdropFilter: "blur(1rem)",
            },
            content: { border: "none", background: "none", inset: 0 },
          }}
        >
          <ResponsiveMenu setShowMenu={setShowMenu} />
        </ReactModal>
      )}
    </Fragment>
  );
};

const NavigationBarProvider = () => (
  <ContractContextProvider>
    <NavigationBar />
  </ContractContextProvider>
);

export default NavigationBarProvider;
