export enum ETransactionStatus {
    NO_ACTION = "NO_ACTION",
    WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
    IN_PROGRESS = "IN_PROGRESS",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    TRAITS_SUCCESS = "TRAITS_SUCCESS",
}

export enum EActionTypes {
    DATA = "DATA",
    ERROR = "ERROR",
    CONFIRMATION = "CONFIRMATION",
    CALLING_CONTRACT = "CALLING_CONTRACT",
    TRANSACTION_HASH = "TRANSACTION_HASH",
}

export enum EContractTransactionStatus {
    CONFIRMATION = "confirmation",
    DATA = "data",
    TRANSACTION_HASH = "transactionHash",
    ERROR = "error",
    RECEIPT = "receipt",
}
