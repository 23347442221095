import { ReactElement, useEffect, useState } from "react";

import styles from "./Modal.module.scss";

import cross from "../../../assets/images/my-collection/cross.png";
import Modal from "react-modal";
import MessageWarning from "./MessageWarning";
import SelectName from "./SelectName";
import OpenToy from "./OpenToy";
import Error from "./Error";
import Success from "./Success";

import { EStepsOpenToy } from "../../../types";
import useScrollLock from "../../../hooks/useScrollLock";
import { useContractContext } from "../../../context/contractContext";
import { ETransactionStatus } from "../../../constants/enums";

interface IProps {
  open: boolean;
  setOpenModal: (value: boolean) => void;
  toyId: number;
}

const ModalMSG = ({ open, setOpenModal, toyId }: IProps): ReactElement => {
  const { lockScroll, unlockScroll } = useScrollLock();
  const { stateOpenToyContract, setStateOpenToyContract } =
    useContractContext();

  const [step, setStep] = useState(EStepsOpenToy.FIRST);
  const [familyName, setFamilyName] = useState("");
  const [validation, setValidation] = useState("");

  const handleClose = () => {
    setStep(EStepsOpenToy.FIRST);
    setOpenModal(false);
  };

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);

  useEffect(() => {
    setStateOpenToyContract({
      type: "CALLING_CONTRACT",
      payload: {
        status: "WAITING_CONFIRMATION",
      },
    });
    setFamilyName("");
  }, [open]);

  return (
    <Modal
      isOpen={open}
      className={styles.modal}
      ariaHideApp={false}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={handleClose}
    >
      <div className={styles.modalContent}>
        <div className={styles.contentCross}>
          <img src={cross} alt="close" onClick={handleClose} />
        </div>
        <div className={styles.content}>
          {step === EStepsOpenToy.FIRST && (
            <MessageWarning
              handleClose={handleClose}
              setStep={setStep}
              step={step}
            />
          )}
          {step === EStepsOpenToy.SECOND && (
            <SelectName
              setStep={setStep}
              step={step}
              handleClose={handleClose}
              familyName={familyName}
              setFamilyName={setFamilyName}
              validation={validation}
              setValidation={setValidation}
            />
          )}
          {step === EStepsOpenToy.THIRD && (
            <OpenToy
              step={step}
              setStep={setStep}
              validation={validation}
              familyName={familyName}
              toyId={toyId}
            />
          )}

          {step === EStepsOpenToy.FOURTH &&
            stateOpenToyContract.payload.status ===
              ETransactionStatus.ERROR && <Error />}

          {step === EStepsOpenToy.FOURTH &&
            stateOpenToyContract.payload.status ===
              ETransactionStatus.IN_PROGRESS && <Success />}
        </div>
      </div>
    </Modal>
  );
};

export default ModalMSG;
